<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  ref,
  computed,
  watch,
} from "vue";
import Chip from "primevue/chip";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import TabMenu from "primevue/tabmenu";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Steps from "primevue/steps";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import Calendar from "primevue/calendar";
import FileUpload from "primevue/fileUpload";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Dropdown from "primevue/dropdown";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Sidebar from "primevue/sidebar";
import ProductOrder from "../api/salary/admin/product_order";
import ProductOrderContent from "../api/salary/admin/product_order_content";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import { dateFormate } from "../tool/formate";
import User from "../api/salary/auth/user";
import firebase from "firebase";
import router from "../router";
import Product from "./ProductShow.vue";

let userApi = new User();
let product_orderAPI = new ProductOrder();
let product_order_contentAPI = new ProductOrderContent();
const store = useStore();
const dt = ref();
function Excel() {
  product_orderAPI
    .getProductOrderList(
      data.product_orderFilters.id.value,
      data.product_orderFilters.orderer_name.value,
      data.product_orderFilters.receiver_name.value,
      data.product_orderFilters.delivery_date_begin.value,
      data.product_orderFilters.delivery_date_end.value,
      data.product_orderFilters.predict_delivery_date_begin.value,
      data.product_orderFilters.predict_delivery_date_end.value,
      data.product_orderFilters.status.value,
      data.product_orderFilters.source_of_order.value,
      data.product_orderFilters.payment_status.value,
      data.product_orderFilters.remark.value,
      1,
      1000
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        let tmp = res.data.data.datas;
        for (let i = 0; i < tmp.length; i++) {
          tmp[i].delivery_date = dateFormate(
            tmp[i].delivery_date,
            "YYYY-MM-DD"
          );
          tmp[i].predict_delivery_date = dateFormate(
            tmp[i].predict_delivery_date,
            "YYYY-MM-DD"
          );
          tmp[i].created_at = dateFormate(tmp[i].created_at, "YYYY-MM-DD");

          tmp[i].source_of_order =
            tmp[i].source_of_order == 1
              ? i18n.$t("ProductOrder").SourceOfOrder.Robots
              : tmp[i].source_of_order == 2
              ? i18n.$t("ProductOrder").SourceOfOrder.Shopee
              : tmp[i].source_of_order == 3
              ? i18n.$t("ProductOrder").SourceOfOrder.ManualInput
              : i18n.$t("ProductOrder").SourceOfOrder.UnKnow;

          tmp[i].payment_status =
            tmp[i].payment_status == 1
              ? i18n.$t("ProductOrder").PaymentStatus.NoPayment
              : tmp[i].payment_status == 2
              ? i18n.$t("ProductOrder").PaymentStatus.InternetCard
              : tmp[i].payment_status == 3
              ? i18n.$t("ProductOrder").PaymentStatus.InternetRemittance
              : tmp[i].payment_status == 4
              ? i18n.$t("ProductOrder").PaymentStatus.StorePayment
              : tmp[i].payment_status == 5
              ? i18n.$t("ProductOrder").PaymentStatus.CashOnDelivery
              : tmp[i].payment_status == 6
              ? i18n.$t("ProductOrder").PaymentStatus.PaymentCompleted
              : i18n.$t("ProductOrder").PaymentStatus.UnKnow;

          tmp[i].status =
            tmp[i].status == 1
              ? i18n.$t("ProductOrder").Status.Establish
              : tmp[i].status == 2
              ? i18n.$t("ProductOrder").Status.Action
              : tmp[i].status == 3
              ? i18n.$t("ProductOrder").Status.Waiting
              : tmp[i].status == 4
              ? i18n.$t("ProductOrder").Status.Complete
              : tmp[i].status == 5
              ? i18n.$t("ProductOrder").Status.Cancel
              : i18n.$t("ProductOrder").Status.UnKnow;
        }
        data.excel.data = tmp;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    })
    .then(() => {
      dt.value.exportCSV();
    });
}
function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  detailDialog: false,
  excel: { data: null },
  product_order_contents: {
    data: [],
  },
  active: 0,
  shopDialog: false,
  items: [
    {
      label: i18n.$t("ProductOrder").Order.Order.Label,
      to: i18n.$t("ProductOrder").Order.Order.To,
    },
    {
      label: i18n.$t("ProductOrder").Order.Content.Label,
      to: i18n.$t("ProductOrder").Order.Content.To,
    },
    {
      label: i18n.$t("ProductOrder").Order.AddContent.Label,
      to: i18n.$t("ProductOrder").Order.AddContent.To,
    },
  ],
  selectStatusList: [
    {
      id: 1,
      name: i18n.$t("ProductOrder").Status.Establish,
    },
    {
      id: 2,
      name: i18n.$t("ProductOrder").Status.Action,
    },
    {
      id: 3,
      name: i18n.$t("ProductOrder").Status.Waiting,
    },
    {
      id: 4,
      name: i18n.$t("ProductOrder").Status.Complete,
    },
    {
      id: 5,
      name: i18n.$t("ProductOrder").Status.Cancel,
    },
  ],
  selectPaymentMethodList: [
    {
      id: 2,
      name: i18n.$t("ProductOrder").PaymentStatus.InternetCard,
    },
    {
      id: 3,
      name: i18n.$t("ProductOrder").PaymentStatus.InternetRemittance,
    },
    {
      id: 4,
      name: i18n.$t("ProductOrder").PaymentStatus.StorePayment,
    },
    {
      id: 5,
      name: i18n.$t("ProductOrder").PaymentStatus.CashOnDelivery,
    },
  ],
  selectPaymentStatusList: [
    {
      id: 1,
      name: i18n.$t("ProductOrder").PaymentStatus.NoPayment,
    },
    {
      id: 2,
      name: i18n.$t("ProductOrder").PaymentStatus.PaymentCompleted,
    },
  ],
  selectSourceOfOrderList: [
    {
      id: 1,
      name: i18n.$t("ProductOrder").SourceOfOrder.Robots,
    },
    {
      id: 2,
      name: i18n.$t("ProductOrder").SourceOfOrder.Shopee,
    },
    {
      id: 3,
      name: i18n.$t("ProductOrder").SourceOfOrder.ManualInput,
    },
  ],
  add_product_order: {
    id: "",
    orderer_name: "",
    orderer_phone: "",
    receiver_name: "",
    receiver_phone: "",
    delivery_date: "",
    predict_delivery_date: "",
    address: "",
    remark: "",
    source_of_order: "",
    payment_status: "",
  },
  product_orderLoading: false,
  totalRecords: 0,
  cancelDialog: false,
  nextDialog: false,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  product_orders: { data: null },
  product_order: null,
  page: 1,
  page_num: 10,
  selectedProductOrders: null,
  product_orderFilters: {
    id: { value: "", matchMode: "contains" },
    orderer_name: { value: "", matchMode: "contains" },
    receiver_name: { value: "", matchMode: "contains" },
    delivery_date_begin: { value: "", matchMode: "contains" },
    delivery_date_end: { value: "", matchMode: "contains" },
    predict_delivery_date_begin: { value: "", matchMode: "contains" },
    predict_delivery_date_end: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
    payment_status: { value: "", matchMode: "contains" },
    payment_method: { value: "", matchMode: "contains" },
    source_of_order: { value: "", matchMode: "contains" },
    remark: { value: "", matchMode: "contains" },
  },
});

const beforemounted = onBeforeMount(async () => {
  CheckUser();
});

const mounted = onMounted(async () => {
  data.product_orderLoading = true;
  if (store.state.user != null) {
    getProductOrderList(false);
  }
});

function ConfirmCancel(product_order) {
  data.cancelDialog = true;
  data.product_order = { ...product_order };
}
function ConfirmNext(product_order) {
  data.nextDialog = true;
  data.product_order = { ...product_order };
}

function getProductOrderList(Show) {
  product_orderAPI
    .getProductOrderList(
      data.product_orderFilters.id.value,
      data.product_orderFilters.orderer_name.value,
      data.product_orderFilters.receiver_name.value,
      data.product_orderFilters.delivery_date_begin.value,
      data.product_orderFilters.delivery_date_end.value,
      data.product_orderFilters.predict_delivery_date_begin.value,
      data.product_orderFilters.predict_delivery_date_end.value,
      data.product_orderFilters.status.value,
      data.product_orderFilters.payment_status.value,
      data.product_orderFilters.source_of_order.value,
      data.product_orderFilters.payment_method.value,
      data.product_orderFilters.remark.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.product_orders.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        if (Show)
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("ProductOrder").Success,
            life: 3000,
          });
        data.product_orderLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.product_orderLoading = false;
      }
    });
}

function saveEditProductOrder() {
  product_orderAPI
    .editProductOrder(
      data.product_order.id,
      data.product_order.orderer_name,
      data.product_order.orderer_phone,
      data.product_order.receiver_name,
      data.product_order.receiver_phone,
      data.product_order.delivery_date,
      data.product_order.predict_delivery_date,
      data.product_order.address,
      data.product_order.remark,
      data.product_order.source_of_order,
      data.product_order.payment_status,
      data.product_order.payment_method
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("ProductOrder").EditSuccess,
          life: 3000,
        });

        data.product_orderLoading = true;
        getProductOrderList(false);
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function saveAddProductOrderContent() {
  let add_data = [];
  for (let i = 0; i < store.state.shop.length; i++) {
    add_data.push({
      product_order_id: data.add_product_order.id,
      product_id: store.state.shop[i].id,
      total_count: String(store.state.shop[i].count),
    });
  }
  product_order_contentAPI.batchProductOrderContent(add_data).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("ProductOrder").Success,
        life: 3000,
      });
      data.addDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function saveAddProductOrder() {
  product_orderAPI
    .addProductOrder(
      data.add_product_order.orderer_name,
      data.add_product_order.orderer_phone,
      data.add_product_order.receiver_name,
      data.add_product_order.receiver_phone,
      data.add_product_order.delivery_date,
      data.add_product_order.predict_delivery_date,
      data.add_product_order.address,
      data.add_product_order.remark,
      data.add_product_order.source_of_order,
      data.add_product_order.payment_status,
      data.add_product_order.payment_method
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("ProductOrder").AddSuccess,
          life: 3000,
        });

        data.add_product_order.id = res.data.data.id;
        data.product_orderLoading = true;
        getProductOrderList(false);
        store.commit("set_shop", []);
        router.push("/product-order/content");
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function addProductOrder() {
  CheckUser();
  data.addDialog = true;
  router.push("/product-order");
  data.add_product_order.id = 0;
  store.commit("set_shop_already", []);
}
function detailProductOrder(product_order) {
  data.product_order = { ...product_order };
  data.detailDialog = true;
  getProductOrderContentList();
}
function editProductOrder(product_order) {
  CheckUser();
  data.product_order = { ...product_order };
  data.editDialog = true;
  data.active = 0;
  store.commit("set_shop", []);
  getProductOrderContentList();
  for (let i = 0; i < data.selectPaymentStatusList.length; i++) {
    if (data.selectPaymentStatusList[i].id == product_order.payment_status) {
      data.PaymentStatusEditSearch = data.selectPaymentStatusList[i];
      break;
    }
  }
  for (let i = 0; i < data.selectPaymentMethodList.length; i++) {
    if (data.selectPaymentMethodList[i].id == product_order.payment_method) {
      data.PaymentMethodEditSearch = data.selectPaymentMethodList[i];
      break;
    }
  }

  for (let i = 0; i < data.selectSourceOfOrderList.length; i++) {
    if (data.selectSourceOfOrderList[i].id == product_order.source_of_order) {
      data.SourceOfOrderEditSearch = data.selectSourceOfOrderList[i];
      break;
    }
  }
}
function confirmDeleteSelected() {
  CheckUser();
  data.deleteSelectedDialog = true;
}
function confirmDelete(product_order) {
  CheckUser();
  data.product_order = product_order;
  data.deleteDialog = true;
}
function deleteProductOrder() {
  product_orderAPI.deleteProductOrder([data.product_order.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("ProductOrder").DeleteSuccess,
        life: 3000,
      });

      data.product_orderLoading = true;
      getProductOrderList(false);
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedProductOrder() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedProductOrders.length; i++) {
    selectDelete.push(data.selectedProductOrders[i].id);
  }
  product_orderAPI.deleteProductOrder(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("ProductOrder").DeleteSuccess,
        life: 3000,
      });

      data.product_orderLoading = true;
      data.selectedProductOrders = [];
      getProductOrderList(false);
      data.deleteSelectedDialog = false;
    } else {
      data.selectedProductOrders = [];
      data.deleteSelectedDialog = false;
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function editProductOrderStatus(product_order, status) {
  product_orderAPI
    .editProductOrderStatus(product_order.id, status)
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("ProductOrder").EditSuccess,
          life: 3000,
        });

        data.product_orderLoading = true;
        getProductOrderList(false);
        data.cancelDialog = false;
        data.nextDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function editProductOrderPaymentStatus(product_order, status) {
  product_orderAPI
    .editProductOrderPaymentStatus(product_order.id, status)
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("ProductOrder").EditSuccess,
          life: 3000,
        });

        data.product_orderLoading = true;
        getProductOrderList(false);
        data.deleteDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function getProductOrderContentList() {
  product_order_contentAPI
    .getProductOrderContentList(data.product_order.id, 1, 500)
    .then((res) => {
      if (res.data.error_code == 0) {
        //   data.content_totalRecords = res.data.data.total_count;
        data.product_order_contents.data = res.data.data.datas;
        store.commit("set_shop_already", res.data.data.datas);
        //   data.content_page = res.data.data.page;
        //   data.product_order_contentLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
        data.product_order_contents.data = [];
        //   data.content_totalRecords = 0;
        //   data.product_order_contentLoading = false;
      }
    });
}
function saveEditProductOrderContent() {
  let add_data = [];
  for (let i = 0; i < store.state.shop.length; i++) {
    add_data.push({
      product_order_id: data.product_order.id,
      product_id: store.state.shop[i].id,
      total_count: String(store.state.shop[i].count),
    });
  }
  product_order_contentAPI.batchProductOrderContent(add_data).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("ProductOrder").Success,
        life: 3000,
      });
      //   data.editDialog = false;
      store.commit("set_shop", []);
      getProductOrderContentList();
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function confirmDeleteContent(product) {
  for (var i = 0; i < store.state.shop.length; i++) {
    var obj = store.state.shop[i];

    if (obj.id == product.id) {
      store.state.shop.splice(i, 1);
    }
  }
}
function confirmDeleteEditContent(product_content) {
  product_order_contentAPI
    .deleteProductOrderContent(
      [product_content.product_id],
      product_content.product_order_id
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("ProductOrder").Success,
          life: 3000,
        });
        getProductOrderContentList();
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function confirmSaveEditContent(product_content) {
  product_order_contentAPI
    .editProductOrderContent(
      product_content.product_id,
      product_content.product_order_id,
      String(product_content.total_count)
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("ProductOrder").Success,
          life: 3000,
        });
        getProductOrderContentList();
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.product_orderLoading = true;
    getProductOrderList(false);
  }
);
watch(
  () => data.product_orderFilters.orderer_name.value,
  () => {
    data.product_orderLoading = true;
    getProductOrderList(false);
  }
);
watch(
  () => data.product_orderFilters.receiver_name.value,
  () => {
    data.product_orderLoading = true;
    getProductOrderList(false);
  }
);
watch(
  () => data.product_orderFilters.id.value,
  () => {
    data.product_orderLoading = true;
    getProductOrderList(false);
  }
);
watch(
  () => data.product_orderFilters.remark.value,
  () => {
    data.product_orderLoading = true;
    getProductOrderList(false);
  }
);
watch(
  () => data.StatusSearch,
  () => {
    data.product_orderLoading = true;
    if (data.StatusSearch != null) {
      data.product_orderFilters.status.value =
        data.StatusSearch == null ? "" : data.StatusSearch.id;
    } else {
      data.product_orderFilters.status.value = "";
    }
    getProductOrderList(false);
  }
);
watch(
  () => data.PaymentStatusSearch,
  () => {
    data.product_orderLoading = true;
    if (data.PaymentStatusSearch != null) {
      data.product_orderFilters.payment_status.value =
        data.PaymentStatusSearch == null ? "" : data.PaymentStatusSearch.id;
    } else {
      data.product_orderFilters.payment_status.value = "";
    }
    getProductOrderList(false);
  }
);
watch(
  () => data.PaymentMethodSearch,
  () => {
    data.product_orderLoading = true;
    if (data.PaymentMethodSearch != null) {
      data.product_orderFilters.payment_method.value =
        data.PaymentMethodSearch == null ? "" : data.PaymentMethodSearch.id;
    } else {
      data.product_orderFilters.payment_method.value = "";
    }
    getProductOrderList(false);
  }
);

watch(
  () => data.SourceOfOrderSearch,
  () => {
    data.product_orderLoading = true;
    if (data.SourceOfOrderSearch != null) {
      data.product_orderFilters.source_of_order.value =
        data.SourceOfOrderSearch == null ? "" : data.SourceOfOrderSearch.id;
    } else {
      data.product_orderFilters.source_of_order.value = "";
    }
    getProductOrderList(false);
  }
);
watch(
  () => data.delivery_date,
  () => {
    data.product_orderLoading = true;
    if (data.delivery_date != null) {
      data.product_orderFilters.delivery_date_begin.value =
        data.delivery_date[0] != null
          ? data.delivery_date[0].toISOString()
          : "";
      data.product_orderFilters.delivery_date_end.value =
        data.delivery_date[1] != null
          ? data.delivery_date[1].toISOString()
          : "";
    } else {
      data.product_orderFilters.delivery_date_begin.value = "";
      data.product_orderFilters.delivery_date_end.value = "";
    }

    getProductOrderList(false);
  }
);

watch(
  () => data.predict_delivery_date,
  () => {
    data.product_orderLoading = true;
    if (data.predict_delivery_date != null) {
      data.product_orderFilters.predict_delivery_date_begin.value =
        data.predict_delivery_date[0] != null
          ? data.predict_delivery_date[0].toISOString()
          : "";
      data.product_orderFilters.predict_delivery_date_end.value =
        data.predict_delivery_date[1] != null
          ? data.predict_delivery_date[1].toISOString()
          : "";
    } else {
      data.product_orderFilters.predict_delivery_date_begin.value = "";
      data.product_orderFilters.predict_delivery_date_end.value = "";
    }

    getProductOrderList(false);
  }
);
watch(
  () => data.PaymentStatusAddSearch,
  () => {
    data.add_product_order.payment_status =
      data.PaymentStatusAddSearch == null ? "" : data.PaymentStatusAddSearch.id;
  }
);
watch(
  () => data.PaymentMethodAddSearch,
  () => {
    data.add_product_order.payment_method =
      data.PaymentMethodAddSearch == null ? "" : data.PaymentMethodAddSearch.id;
  }
);

watch(
  () => data.SourceOfOrderAddSearch,
  () => {
    data.add_product_order.source_of_order =
      data.SourceOfOrderAddSearch == null ? "" : data.SourceOfOrderAddSearch.id;
  }
);
watch(
  () => data.PaymentStatusEditSearch,
  () => {
    data.product_order.payment_status =
      data.PaymentStatusEditSearch == null
        ? ""
        : data.PaymentStatusEditSearch.id;
  }
);
watch(
  () => data.PaymentMethodEditSearch,
  () => {
    data.product_order.payment_method =
      data.PaymentMethodEditSearch == null
        ? ""
        : data.PaymentMethodEditSearch.id;
  }
);

watch(
  () => data.SourceOfOrderEditSearch,
  () => {
    data.product_order.source_of_order =
      data.SourceOfOrderEditSearch == null
        ? ""
        : data.SourceOfOrderEditSearch.id;
  }
);
const add_deliver_1 = ref();
const add_deliver_2 = ref();
const edit_deliver_1 = ref();
const edit_deliver_2 = ref();

function closeCalendar(item) {
  switch (item) {
    case "add_deliver_1":
      add_deliver_1.value.overlayVisible = false;
      break;
    case "add_deliver_2":
      add_deliver_2.value.overlayVisible = false;
      break;
    case "edit_deliver_1":
      edit_deliver_1.value.overlayVisible = false;
      break;
    case "edit_deliver_2":
      edit_deliver_2.value.overlayVisible = false;
      break;
  }
}
function todayCalendar(item) {
  let date = new Date();
  let dateMeta = {
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
    otherMonth:
      date.getMonth() !== this.currentMonth ||
      date.getFullYear() !== this.currentYear,
    today: true,
    selectable: true,
  };
  switch (item) {
    case "add_deliver_1":
      add_deliver_1.value.onDateSelect(null, dateMeta);
      add_deliver_1.value.$emit("today-click", date);
      break;
    case "add_deliver_2":
      add_deliver_2.value.onDateSelect(null, dateMeta);
      add_deliver_2.value.$emit("today-click", date);
      break;
    case "edit_deliver_1":
      edit_deliver_1.value.onDateSelect(null, dateMeta);
      edit_deliver_1.value.$emit("today-click", date);
      break;
    case "edit_deliver_2":
      edit_deliver_2.value.onDateSelect(null, dateMeta);
      edit_deliver_2.value.$emit("today-click", date);
      break;
  }
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.product_orders.data"
      :loading="data.product_orderLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.product_orderFilters"
      v-model:selection="data.selectedProductOrders"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{
                  i18n.$t("ProductOrder").ProductOrderTable.Search
                }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.product_orderFilters[
                        i18n.$t('ProductOrder').ProductOrderTable.ID.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('ProductOrder').ProductOrderTable.ID.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.product_orderFilters[
                        i18n.$t('ProductOrder').ProductOrderTable.OrdererName
                          .Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('ProductOrder').ProductOrderTable.OrdererName.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.product_orderFilters[
                        i18n.$t('ProductOrder').ProductOrderTable.ReceiverName
                          .Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('ProductOrder').ProductOrderTable.ReceiverName
                        .Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    class="p-ml-1"
                    v-model="data.StatusSearch"
                    :options="data.selectStatusList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Product').ProductTable.Status.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    class="p-ml-1"
                    v-model="data.PaymentMethodSearch"
                    :options="data.selectPaymentMethodList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('ProductOrder').ProductOrderTable.PaymentMethod
                        .Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    class="p-ml-1"
                    v-model="data.PaymentStatusSearch"
                    :options="data.selectPaymentStatusList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('ProductOrder').ProductOrderTable.PaymentStatus
                        .Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    class="p-ml-1"
                    v-model="data.SourceOfOrderSearch"
                    :options="data.selectSourceOfOrderList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('ProductOrder').ProductOrderTable.SourceOfOrder
                        .Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </p>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search p-mr-1"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.product_orderFilters[
                        i18n.$t('ProductOrder').ProductOrderTable.Remark.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('ProductOrder').ProductOrderTable.Remark.Show
                    "
                  />
                </span>
              </p>
              <div class="p-col-12" style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.delivery_date"
                    selectionMode="range"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('ProductOrder').ProductOrderTable.DeliveryDate
                        .Show
                    "
                    :showButtonBar="true"
                  />
                </span>
              </div>
              <div class="p-col-12" style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.predict_delivery_date"
                    selectionMode="range"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('ProductOrder').ProductOrderTable
                        .PredictDeliveryDate.Show
                    "
                    :showButtonBar="true"
                  />
                </span>
              </div>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              icon="pi pi-refresh"
              class="p-button-rounded p-button-secondary p-mr-2"
              @click="getProductOrderList(true)"
            />
            <Button
              icon="pi pi-print"
              class="p-button-rounded p-button-info p-mr-2"
              @click="Excel"
            />
            <Button
              :label="i18n.$t('ProductOrder').ProductOrderTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addProductOrder"
            />
            <Button
              :label="i18n.$t('ProductOrder').ProductOrderTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedProductOrders ||
                !data.selectedProductOrders.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        :field="i18n.$t('ProductOrder').ProductOrderTable.ID.Field"
        :header="i18n.$t('ProductOrder').ProductOrderTable.ID.Show"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      ></Column>
      <Column
        headerStyle="width: 500px"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
        :field="i18n.$t('ProductOrder').ProductOrderTable.OrdererName.Field"
        :header="i18n.$t('ProductOrder').ProductOrderTable.OrdererName.Show"
      ></Column>
      <Column
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
        :field="i18n.$t('ProductOrder').ProductOrderTable.OrdererPhone.Field"
        :header="i18n.$t('ProductOrder').ProductOrderTable.OrdererPhone.Show"
      ></Column>
      <Column
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
        :field="i18n.$t('ProductOrder').ProductOrderTable.Address.Field"
        :header="i18n.$t('ProductOrder').ProductOrderTable.Address.Show"
      ></Column>
      <Column
        :header="
          i18n.$t('ProductOrder').ProductOrderTable.PredictDeliveryDate.Show
        "
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      >
        <template #body="slotProps">
          {{
            slotProps.data.predict_delivery_date == ""
              ? i18n.$t("ProductOrder").UnKnow
              : dateFormate(
                  slotProps.data.predict_delivery_date,
                  "YYYY-MM-DD hh:mm"
                )
          }}
        </template>
      </Column>
      <Column
        :header="i18n.$t('ProductOrder').ProductOrderTable.DeliveryDate.Show"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      >
        <template #body="slotProps">
          {{
            slotProps.data.delivery_date == ""
              ? i18n.$t("ProductOrder").UnKnow
              : dateFormate(slotProps.data.delivery_date, "YYYY-MM-DD hh:mm")
          }}
        </template>
      </Column>
      <Column
        :field="i18n.$t('ProductOrder').ProductOrderTable.TotalCount.Field"
        :header="i18n.$t('ProductOrder').ProductOrderTable.TotalCount.Show"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      ></Column>
      <Column
        :header="i18n.$t('ProductOrder').ProductOrderTable.TotalDollar.Show"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      >
        <template #body="slotProps">
          {{ slotProps.data.total_settlement_dollar }}<br />
          {{ "(原價:" + slotProps.data.total_dollar + ")" }}
        </template>
      </Column>
      <Column
        :header="i18n.$t('ProductOrder').ProductOrderTable.Discount.Show"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      >
        <template #body="slotProps">
          {{ slotProps.data.total_add_sub_dollar }}
          <Chip
            v-for="item in slotProps.data.product_order_add_sub_records"
            :key="item.add_sub_name"
            class="ip-mr-1"
          >
            {{ item.add_sub_name + ":" + item.dollar }}
          </Chip>
        </template>
      </Column>
      <Column
        class="p-col-2"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
        :header="i18n.$t('ProductOrder').ProductOrderTable.SourceOfOrder.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.source_of_order == 1
              ? i18n.$t("ProductOrder").SourceOfOrder.Robots
              : slotProps.data.source_of_order == 2
              ? i18n.$t("ProductOrder").SourceOfOrder.Shopee
              : slotProps.data.source_of_order == 3
              ? i18n.$t("ProductOrder").SourceOfOrder.ManualInput
              : i18n.$t("ProductOrder").SourceOfOrder.UnKnow
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
        :header="i18n.$t('ProductOrder').ProductOrderTable.PaymentMethod.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.payment_method == 2
              ? i18n.$t("ProductOrder").PaymentStatus.InternetCard
              : slotProps.data.payment_method == 3
              ? i18n.$t("ProductOrder").PaymentStatus.InternetRemittance
              : slotProps.data.payment_method == 4
              ? i18n.$t("ProductOrder").PaymentStatus.StorePayment
              : slotProps.data.payment_method == 5
              ? i18n.$t("ProductOrder").PaymentStatus.CashOnDelivery
              : i18n.$t("ProductOrder").PaymentStatus.UnKnow
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
        :header="i18n.$t('ProductOrder').ProductOrderTable.PaymentStatus.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.payment_status == 1
              ? i18n.$t("ProductOrder").PaymentStatus.NoPayment
              : slotProps.data.payment_status == 2
              ? i18n.$t("ProductOrder").PaymentStatus.PaymentCompleted
              : i18n.$t("ProductOrder").PaymentStatus.UnKnow
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
        :header="i18n.$t('ProductOrder').ProductOrderTable.Status.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.status == 1
              ? i18n.$t("ProductOrder").Status.Establish
              : slotProps.data.status == 2
              ? i18n.$t("ProductOrder").Status.Action
              : slotProps.data.status == 3
              ? i18n.$t("ProductOrder").Status.Waiting
              : slotProps.data.status == 4
              ? i18n.$t("ProductOrder").Status.Complete
              : slotProps.data.status == 5
              ? i18n.$t("ProductOrder").Status.Cancel
              : i18n.$t("ProductOrder").Status.UnKnow
          }}
        </template>
      </Column>
      <Column
        :field="i18n.$t('ProductOrder').ProductOrderTable.Remark.Field"
        :header="i18n.$t('ProductOrder').ProductOrderTable.Remark.Show"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      ></Column>
      <Column
        :header="i18n.$t('ProductOrder').ProductOrderTable.CreatedAt.Show"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      >
        <template #body="slotProps">
          {{
            slotProps.data.created_at == ""
              ? i18n.$t("ProductOrder").UnKnow
              : dateFormate(slotProps.data.created_at, "YYYY-MM-DD")
          }}
        </template>
      </Column>
      <Column bodyStyle="white-space: nowrap; width: 400px;" class="p-col-2">
        <template #body="slotProps">
          <Button
            v-tooltip="i18n.$t('ProductOrder').ToolTip.Edit"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-info p-mr-2"
            @click="editProductOrder(slotProps.data)"
          />
          <Button
            v-tooltip="i18n.$t('ProductOrder').ToolTip.Eye"
            icon="pi pi-eye"
            class="p-button-rounded p-button-info p-mr-2"
            @click="detailProductOrder(slotProps.data)"
          />
          <Button
            v-tooltip="i18n.$t('ProductOrder').ToolTip.Delete"
            v-if="slotProps.data.status != 4 && slotProps.data.status != 5"
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning p-mr-2"
            @click="confirmDelete(slotProps.data)"
          />
          <Button
            v-tooltip="i18n.$t('ProductOrder').ToolTip.Cancel"
            v-if="slotProps.data.status != 4 && slotProps.data.status != 5"
            icon="pi pi-ban"
            class="p-button-rounded p-button-danger p-mr-2"
            @click="ConfirmCancel(slotProps.data)"
          />
          <Button
            v-tooltip="i18n.$t('ProductOrder').ToolTip.Next"
            v-if="slotProps.data.status != 4 && slotProps.data.status != 5"
            icon="pi pi-check"
            class="p-button-rounded p-button-success"
            @click="ConfirmNext(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '650px', minWidth: '350px' }"
      :header="i18n.$t('ProductOrder').AddProductOrder.Title"
      :modal="true"
      class="p-fluid"
    >
      <Steps :model="data.items" :readonly="true"></Steps>
      <div v-if="router.currentRoute.value.path == '/product-order/content'">
        <Button
          :label="i18n.$t('ProductOrder').Shop"
          icon="pi pi-shopping-cart"
          class="p-button-info p-mt-1"
          @click="data.shopDialog = true"
        />
        <div class="p-grid p-mt-2" style="border-bottom: 1px solid">
          <div class="p-col-3">
            {{ i18n.$t("Product").ProductTable.Name.Show }}
          </div>
          <div class="p-col-2">
            {{ i18n.$t("Product").ProductTable.Dollar.Show }}
          </div>
          <div class="p-col-3">
            {{ i18n.$t("Product").ProductTable.Image.Show }}
          </div>
          <div class="p-col-3">
            {{ i18n.$t("Product").ProductTable.Count.Show }}
          </div>
          <div class="p-col-1"></div>
        </div>
        <div
          :key="index"
          v-for="(item, index) in store.state.shop"
          class="p-grid"
          style="border-bottom: 1px solid"
        >
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-3"
          >
            {{ item.name }}
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-2"
          >
            {{ item.dollar }}
          </div>
          <div style="display: flex; align-items: center" class="p-col-3">
            <img class="product-image" :src="item.image_url" />
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-3"
          >
            <InputNumber
              v-model="item.count"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            />
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-1"
          >
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger"
              @click="confirmDeleteContent(item)"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("ProductOrder").ProductOrderTable.OrdererName.Show
          }}</label>
          <InputText
            id="name"
            v-model.trim="data.add_product_order.orderer_name"
            required="true"
          />
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("ProductOrder").ProductOrderTable.OrdererPhone.Show
          }}</label>
          <InputText
            id="name"
            v-model.trim="data.add_product_order.orderer_phone"
            required="true"
          />
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("ProductOrder").ProductOrderTable.ReceiverName.Show
          }}</label>
          <InputText
            id="name"
            v-model.trim="data.add_product_order.receiver_name"
            required="true"
          />
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("ProductOrder").ProductOrderTable.ReceiverPhone.Show
          }}</label>
          <InputText
            id="name"
            v-model.trim="data.add_product_order.receiver_phone"
            required="true"
          />
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("ProductOrder").ProductOrderTable.Address.Show
          }}</label>
          <InputText
            id="name"
            v-model.trim="data.add_product_order.address"
            required="true"
          />
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("ProductOrder").ProductOrderTable.PredictDeliveryDate.Show
          }}</label>
          <Calendar
            id="range"
            :showTime="true"
            v-model="data.add_product_order.predict_delivery_date"
            :manualInput="false"
            :showIcon="true"
            :placeholder="
              i18n.$t('ProductOrder').ProductOrderTable.PredictDeliveryDate.Show
            "
            ref="add_deliver_1"
          >
            <template #footer>
              <div class="p-datepicker-buttonbar">
                <Button
                  @click="todayCalendar('add_deliver_1')"
                  class="p-button p-component p-button-text"
                  >Today</Button
                >
                <Button
                  @click="closeCalendar('add_deliver_1')"
                  class="p-button p-component p-button-text"
                  >Submit</Button
                >
              </div>
            </template>
          </Calendar>
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("ProductOrder").ProductOrderTable.DeliveryDate.Show
          }}</label>
          <Calendar
            id="range"
            :showTime="true"
            v-model="data.add_product_order.delivery_date"
            :manualInput="false"
            :showIcon="true"
            :placeholder="
              i18n.$t('ProductOrder').ProductOrderTable.DeliveryDate.Show
            "
            ref="add_deliver_2"
          >
            <template #footer>
              <div class="p-datepicker-buttonbar">
                <Button
                  @click="todayCalendar('add_deliver_2')"
                  class="p-button p-component p-button-text"
                  >Today</Button
                >
                <Button
                  @click="closeCalendar('add_deliver_2')"
                  class="p-button p-component p-button-text"
                  >Submit</Button
                >
              </div>
            </template>
          </Calendar>
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("ProductOrder").ProductOrderTable.PaymentMethod.Show
          }}</label>
          <Dropdown
            v-model="data.PaymentMethodAddSearch"
            :options="data.selectPaymentMethodList"
            optionLabel="name"
            :filter="true"
            :placeholder="
              i18n.$t('ProductOrder').ProductOrderTable.PaymentMethod.Show
            "
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("ProductOrder").ProductOrderTable.PaymentStatus.Show
          }}</label>
          <Dropdown
            v-model="data.PaymentStatusAddSearch"
            :options="data.selectPaymentStatusList"
            optionLabel="name"
            :filter="true"
            :placeholder="
              i18n.$t('ProductOrder').ProductOrderTable.PaymentStatus.Show
            "
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("ProductOrder").ProductOrderTable.SourceOfOrder.Show
          }}</label>
          <Dropdown
            v-model="data.SourceOfOrderAddSearch"
            :options="data.selectSourceOfOrderList"
            optionLabel="name"
            :filter="true"
            :placeholder="
              i18n.$t('ProductOrder').ProductOrderTable.SourceOfOrder.Show
            "
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-field">
          <label for="name">{{
            i18n.$t("ProductOrder").ProductOrderTable.Remark.Show
          }}</label>
          <Textarea
            id="description"
            v-model="data.add_product_order.remark"
            required="true"
            rows="3"
            cols="20"
          />
        </div>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('ProductOrder').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          v-if="router.currentRoute.value.path == '/product-order/content'"
          :label="i18n.$t('ProductOrder').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddProductOrderContent"
        />
        <Button
          v-else
          :label="i18n.$t('ProductOrder').Next"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddProductOrder"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.detailDialog"
      :style="{ width: '650px', minWidth: '350px' }"
      :header="i18n.$t('ProductOrder').DetailProductOrder.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-grid" style="border-bottom: 1px solid">
        <div class="p-col-3">
          {{ i18n.$t("Product").ProductTable.Name.Show }}
        </div>
        <div class="p-col-2">
          {{ i18n.$t("Product").ProductTable.Dollar.Show }}
        </div>
        <div class="p-col-3">
          {{ i18n.$t("Product").ProductTable.Image.Show }}
        </div>
        <div class="p-col-2">
          {{ i18n.$t("Product").ProductTable.Count.Show }}
        </div>
        <div class="p-col-2"></div>
      </div>
      <div
        :key="index"
        v-for="(item, index) in data.product_order_contents.data"
        class="p-grid"
        style="border-bottom: 1px solid"
      >
        <div
          style="
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: auto;
          "
          class="p-col-3"
        >
          {{ item.product_name }}
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: auto;
          "
          class="p-col-2"
        >
          {{ item.dollar }}
        </div>
        <div style="display: flex; align-items: center" class="p-col-3">
          <img class="product-image" :src="item.product_image_url" />
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: auto;
          "
          class="p-col-2"
        >
          {{ item.total_count }}
        </div>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("ProductOrder").ProductOrderTable.Remark.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.product_order.remark"
          required="true"
          disabled
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('ProductOrder').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.detailDialog = false"
        />
      </template>
    </Dialog>
    <!-- 修改視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '650px', minWidth: '350px' }"
      :header="i18n.$t('ProductOrder').EditProductOrder.Title"
      :modal="true"
      class="p-fluid"
    >
      <TabView v-model:activeIndex="data.active">
        <TabPanel :header="i18n.$t('ProductOrder').Order.Order.Label">
          <div class="p-field">
            <label for="name">{{
              i18n.$t("ProductOrder").ProductOrderTable.OrdererName.Show
            }}</label>
            <InputText
              id="name"
              v-model.trim="data.product_order.orderer_name"
              required="true"
            />
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("ProductOrder").ProductOrderTable.OrdererPhone.Show
            }}</label>
            <InputText
              id="name"
              v-model.trim="data.product_order.orderer_phone"
              required="true"
            />
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("ProductOrder").ProductOrderTable.ReceiverName.Show
            }}</label>
            <InputText
              id="name"
              v-model.trim="data.product_order.receiver_name"
              required="true"
            />
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("ProductOrder").ProductOrderTable.ReceiverPhone.Show
            }}</label>
            <InputText
              id="name"
              v-model.trim="data.product_order.receiver_phone"
              required="true"
            />
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("ProductOrder").ProductOrderTable.Address.Show
            }}</label>
            <InputText
              id="name"
              v-model.trim="data.product_order.address"
              required="true"
            />
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("ProductOrder").ProductOrderTable.PredictDeliveryDate.Show
            }}</label>
            <Calendar
              id="range"
              :showTime="true"
              v-model="data.product_order.predict_delivery_date"
              :manualInput="false"
              :showIcon="true"
              :placeholder="
                i18n.$t('ProductOrder').ProductOrderTable.PredictDeliveryDate
                  .Show
              "
              ref="edit_deliver_1"
            >
              <template #footer>
                <div class="p-datepicker-buttonbar">
                  <Button
                    @click="todayCalendar('edit_deliver_1')"
                    class="p-button p-component p-button-text"
                    >Today</Button
                  >
                  <Button
                    @click="closeCalendar('edit_deliver_1')"
                    class="p-button p-component p-button-text"
                    >Submit</Button
                  >
                </div>
              </template>
            </Calendar>
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("ProductOrder").ProductOrderTable.DeliveryDate.Show
            }}</label>
            <Calendar
              id="range"
              :showTime="true"
              v-model="data.product_order.delivery_date"
              :manualInput="false"
              :showIcon="true"
              :placeholder="
                i18n.$t('ProductOrder').ProductOrderTable.DeliveryDate.Show
              "
              ref="edit_deliver_2"
            >
              <template #footer>
                <div class="p-datepicker-buttonbar">
                  <Button
                    @click="todayCalendar('edit_deliver_2')"
                    class="p-button p-component p-button-text"
                    >Today</Button
                  >
                  <Button
                    @click="closeCalendar('edit_deliver_2')"
                    class="p-button p-component p-button-text"
                    >Submit</Button
                  >
                </div>
              </template>
            </Calendar>
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("ProductOrder").ProductOrderTable.PaymentMethod.Show
            }}</label>
            <Dropdown
              v-model="data.PaymentMethodEditSearch"
              :options="data.selectPaymentMethodList"
              optionLabel="name"
              :filter="true"
              :placeholder="
                i18n.$t('ProductOrder').ProductOrderTable.PaymentMethod.Show
              "
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("ProductOrder").ProductOrderTable.PaymentStatus.Show
            }}</label>
            <Dropdown
              v-model="data.PaymentStatusEditSearch"
              :options="data.selectPaymentStatusList"
              optionLabel="name"
              :filter="true"
              :placeholder="
                i18n.$t('ProductOrder').ProductOrderTable.PaymentStatus.Show
              "
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("ProductOrder").ProductOrderTable.SourceOfOrder.Show
            }}</label>
            <Dropdown
              v-model="data.SourceOfOrderEditSearch"
              :options="data.selectSourceOfOrderList"
              optionLabel="name"
              :filter="true"
              :placeholder="
                i18n.$t('ProductOrder').ProductOrderTable.SourceOfOrder.Show
              "
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="p-field">
            <label for="name">{{
              i18n.$t("ProductOrder").ProductOrderTable.Remark.Show
            }}</label>
            <Textarea
              id="description"
              v-model="data.product_order.remark"
              required="true"
              rows="3"
              cols="20"
            />
          </div>
        </TabPanel>
        <TabPanel :header="i18n.$t('ProductOrder').Order.Content.Label">
          <!-- <h4 class="p-text-center">{{ i18n.$t("ProductOrder").Old }}</h4> -->
          <div class="p-grid" style="border-bottom: 1px solid">
            <div class="p-col-3">
              {{ i18n.$t("Product").ProductTable.Name.Show }}
            </div>
            <div class="p-col-2">
              {{ i18n.$t("Product").ProductTable.Dollar.Show }}
            </div>
            <div class="p-col-3">
              {{ i18n.$t("Product").ProductTable.Image.Show }}
            </div>
            <div class="p-col-2">
              {{ i18n.$t("Product").ProductTable.Count.Show }}
            </div>
            <div class="p-col-2"></div>
          </div>
          <div
            :key="index"
            v-for="(item, index) in data.product_order_contents.data"
            class="p-grid"
            style="border-bottom: 1px solid"
          >
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
              "
              class="p-col-3"
            >
              {{ item.product_name }}
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
              "
              class="p-col-2"
            >
              {{ item.dollar }}
            </div>
            <div style="display: flex; align-items: center" class="p-col-3">
              <img class="product-image" :src="item.product_image_url" />
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
              "
              class="p-col-2"
            >
              <InputNumber
                v-model="item.total_count"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="5"
                required="true"
              />
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
              "
              class="p-col-2"
            >
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger"
                @click="confirmDeleteEditContent(item)"
              />
              <Button
                icon="pi pi-check"
                class="p-button-rounded p-button-info"
                @click="confirmSaveEditContent(item)"
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel :header="i18n.$t('ProductOrder').Order.AddContent.Label">
          <Button
            :label="i18n.$t('ProductOrder').Shop"
            icon="pi pi-shopping-cart"
            class="p-button-info p-mt-2"
            @click="data.shopDialog = true"
          />
          <h4 class="p-text-center">{{ i18n.$t("ProductOrder").New }}</h4>
          <div class="p-grid p-mt-2" style="border-bottom: 1px solid">
            <div class="p-col-3">
              {{ i18n.$t("Product").ProductTable.Name.Show }}
            </div>
            <div class="p-col-2">
              {{ i18n.$t("Product").ProductTable.Dollar.Show }}
            </div>
            <div class="p-col-3">
              {{ i18n.$t("Product").ProductTable.Image.Show }}
            </div>
            <div class="p-col-3">
              {{ i18n.$t("Product").ProductTable.Count.Show }}
            </div>
            <div class="p-col-1"></div>
          </div>
          <div
            :key="index"
            v-for="(item, index) in store.state.shop"
            class="p-grid"
            style="border-bottom: 1px solid"
          >
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
              "
              class="p-col-3"
            >
              {{ item.name }}
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
              "
              class="p-col-2"
            >
              {{ item.dollar }}
            </div>
            <div style="display: flex; align-items: center" class="p-col-3">
              <img class="product-image" :src="item.image_url" />
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
              "
              class="p-col-3"
            >
              <InputNumber
                v-model="item.count"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="5"
                required="true"
              />
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
              "
              class="p-col-1"
            >
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger"
                @click="confirmDeleteContent(item)"
              />
            </div>
          </div>
        </TabPanel>
      </TabView>

      <template #footer>
        <Button
          :label="i18n.$t('ProductOrder').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          v-if="data.active == 0"
          :label="i18n.$t('ProductOrder').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditProductOrder"
        />
        <Button
          v-if="data.active == 2"
          :label="i18n.$t('ProductOrder').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditProductOrderContent"
        />
      </template>
    </Dialog>
    <!-- 確認取消視窗 -->
    <Dialog
      v-model:visible="data.cancelDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('ProductOrder').ConfirmCancel.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("ProductOrder").ConfirmCancel.Content }} ?</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('ProductOrder').ConfirmCancel.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.cancelDialog = false"
        />
        <Button
          :label="i18n.$t('ProductOrder').ConfirmCancel.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="editProductOrderStatus(data.product_order, 5)"
        />
      </template>
    </Dialog>
    <!-- 確認下一步視窗 -->
    <Dialog
      v-model:visible="data.nextDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('ProductOrder').ConfirmNext.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span
          >{{ i18n.$t("ProductOrder").ConfirmNext.Content }}({{
            data.product_order.status + 1 == 1
              ? i18n.$t("ProductOrder").Status.Establish
              : data.product_order.status + 1 == 2
              ? i18n.$t("ProductOrder").Status.Action
              : data.product_order.status + 1 == 3
              ? i18n.$t("ProductOrder").Status.Waiting
              : data.product_order.status + 1 == 4
              ? i18n.$t("ProductOrder").Status.Complete
              : data.product_order.status + 1 == 5
              ? i18n.$t("ProductOrder").Status.Cancel
              : i18n.$t("ProductOrder").Status.UnKnow
          }}) ?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('ProductOrder').ConfirmNext.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.nextDialog = false"
        />
        <Button
          :label="i18n.$t('ProductOrder').ConfirmNext.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="
            editProductOrderStatus(
              data.product_order,
              data.product_order.status + 1
            )
          "
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('ProductOrder').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.product_order"
          >{{ i18n.$t("ProductOrder").Confirm.Content }} ?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('ProductOrder').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('ProductOrder').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteProductOrder"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('ProductOrder').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("ProductOrder").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('ProductOrder').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('ProductOrder').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedProductOrder"
        />
      </template>
    </Dialog>
    <!-- 選擇商品視窗 -->
    <Sidebar
      style="min-width: 375px"
      v-model:visible="data.shopDialog"
      position="right"
    >
      <Product />
    </Sidebar>
  </div>
  <DataTable
    class="p-d-none"
    :value="data.excel.data"
    ref="dt"
    responsiveLayout="scroll"
  >
    <Column
      :field="i18n.$t('ProductOrder').ProductOrderTable.ID.Field"
      :header="i18n.$t('ProductOrder').ProductOrderTable.ID.Show"
      bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
    ></Column>
    <Column
      headerStyle="width: 500px"
      bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      :field="i18n.$t('ProductOrder').ProductOrderTable.OrdererName.Field"
      :header="i18n.$t('ProductOrder').ProductOrderTable.OrdererName.Show"
    ></Column>
    <Column
      bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      :field="i18n.$t('ProductOrder').ProductOrderTable.OrdererPhone.Field"
      :header="i18n.$t('ProductOrder').ProductOrderTable.OrdererPhone.Show"
    ></Column>
    <Column
      bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      :field="i18n.$t('ProductOrder').ProductOrderTable.Address.Field"
      :header="i18n.$t('ProductOrder').ProductOrderTable.Address.Show"
    ></Column>
    <Column
      :header="
        i18n.$t('ProductOrder').ProductOrderTable.PredictDeliveryDate.Show
      "
      :field="
        i18n.$t('ProductOrder').ProductOrderTable.PredictDeliveryDate.Field
      "
      bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
    >
    </Column>
    <Column
      :header="i18n.$t('ProductOrder').ProductOrderTable.DeliveryDate.Show"
      :field="i18n.$t('ProductOrder').ProductOrderTable.DeliveryDate.Field"
      bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
    >
    </Column>
    <Column
      :field="i18n.$t('ProductOrder').ProductOrderTable.TotalCount.Field"
      :header="i18n.$t('ProductOrder').ProductOrderTable.TotalCount.Show"
      bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
    ></Column>
    <Column
      :field="i18n.$t('ProductOrder').ProductOrderTable.TotalDollar.Field"
      :header="i18n.$t('ProductOrder').ProductOrderTable.TotalDollar.Show"
      bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
    ></Column>
    <Column
      :field="i18n.$t('ProductOrder').ProductOrderTable.Remark.Field"
      :header="i18n.$t('ProductOrder').ProductOrderTable.Remark.Show"
      bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
    ></Column>

    <Column
      class="p-col-2"
      bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      :header="i18n.$t('ProductOrder').ProductOrderTable.SourceOfOrder.Show"
      :field="i18n.$t('ProductOrder').ProductOrderTable.SourceOfOrder.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      :header="i18n.$t('ProductOrder').ProductOrderTable.PaymentStatus.Show"
      :field="i18n.$t('ProductOrder').ProductOrderTable.PaymentStatus.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      :header="i18n.$t('ProductOrder').ProductOrderTable.Status.Show"
      :field="i18n.$t('ProductOrder').ProductOrderTable.Status.Field"
    >
    </Column>
    <Column
      :header="i18n.$t('ProductOrder').ProductOrderTable.CreatedAt.Show"
      :field="i18n.$t('ProductOrder').ProductOrderTable.CreatedAt.Field"
      bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
    >
    </Column>
  </DataTable>
</template>

<style scoped>
.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
