import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class StoreInformation {
  getStoreInformationInfo(id) {
    return axios.get(
      `/line/store-information/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
}
