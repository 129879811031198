import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "buttersea",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Product {
  getProductInfo(id) {
    return axios.get(
      `/api/admin/product/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getProductList(code, name, status, is_top, page, page_num) {
    return axios.get(
      `/api/admin/product/list`,
      {
        params: {
          code: code,
          name: name,
          status: status,
          is_top: is_top,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchProduct(datas) {
    return axios.post(
      `/api/admin/batch/product`,
      {
        datas: datas,
      },
      config
    );
  }
  addProduct(
    name,
    code,
    dollar,
    count,
    image,
    is_top,
    new_product_type_ids,
    new_add_sub_list_codes,
    delete_add_sub_list_codes
  ) {
    return axios.post(
      `/api/admin/product/`,
      {
        name: name,
        code: code,
        dollar: dollar,
        count: count,
        image: image,
        is_top: is_top,
        new_product_type_ids: new_product_type_ids,
        new_add_sub_list_codes: new_add_sub_list_codes,
        delete_add_sub_list_codes: delete_add_sub_list_codes,
      },
      config
    );
  }
  editProduct(
    id,
    name,
    code,
    dollar,
    count,
    image,
    is_top,
    new_product_type_ids,
    delete_product_type_ids,
    description
  ) {
    return axios.put(
      `/api/admin/product/`,
      {
        id: id,
        name: name,
        code: code,
        dollar: dollar,
        count: count,
        image: image,
        is_top: is_top,
        new_product_type_ids: new_product_type_ids,
        delete_product_type_ids: delete_product_type_ids,
        description: description,
      },
      config
    );
  }
  editProductCoupon(id, new_add_sub_lists, delete_add_sub_list_ids) {
    return axios.put(
      `/api/admin/product/`,
      {
        id: id,
        new_add_sub_lists: new_add_sub_lists,
        delete_add_sub_list_ids: delete_add_sub_list_ids,
      },
      config
    );
  }
  editProductStatus(id, status) {
    return axios.put(
      `/api/admin/product/`,
      {
        id: id,
        status: status,
      },
      config
    );
  }
  deleteProduct(id) {
    return axios.delete(
      `/api/admin/product`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
