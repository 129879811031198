import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config";
import Tooltip from "primevue/tooltip";
import "primeflex/primeflex.scss";
// import "primevue/resources/themes/vela-green/theme.css";
import "primevue/resources/themes/saga-green/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import ToastService from "primevue/toastservice";
import vue3JsonExcel from "vue3-json-excel";
import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyAX3SJ_gnpNvvsD6Naa8wd2n2HpcARdU4c",
  authDomain: "leyuafrica.firebaseapp.com",
  projectId: "leyuafrica",
  storageBucket: "leyuafrica.appspot.com",
  messagingSenderId: "721624802563",
  appId: "1:721624802563:web:e8bd55ec1db745877db536",
  measurementId: "G-S261HM59E9",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
import { ethers } from "ethers";
// import vueXlsxTable from "vue-xlsx-table";
// convertedData.body
createApp(App)
  .use(router)
  .use(store)
  .use(ToastService)
  .use(PrimeVue)
  .use(vue3JsonExcel)
  .use(ethers)
  .directive("tooltip", Tooltip)
  .mount("#app");
