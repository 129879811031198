import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "buttersea",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class ProductOrderContent {
  getProductOrderContentList(product_order_id, page, page_num) {
    return axios.get(
      `/api/admin/product-order-content/list`,
      {
        params: {
          product_order_id: product_order_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchProductOrderContent(datas) {
    return axios.post(
      `/api/admin/product-order-content/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addProductOrderContent(product_order_id, product_id, total_count) {
    return axios.post(
      `/api/admin/product-order-content`,
      {
        product_order_id: product_order_id,
        product_id: product_id,
        total_count: total_count,
      },
      config
    );
  }
  editProductOrderContent(product_id, product_order_id, total_count) {
    return axios.put(
      `/api/admin/product-order-content`,
      {
        product_id: product_id,
        product_order_id: product_order_id,
        total_count: total_count,
      },
      config
    );
  }
  deleteProductOrderContent(product_ids, product_order_id) {
    return axios.delete(
      `/api/admin/product-order-content`,
      {
        data: {
          product_ids: product_ids,
          product_order_id: product_order_id,
        },
      },
      config
    );
  }
}
