export function dateFormate(dateString, formate) {
  let date = new Date(dateString);
  const GMT_8 = date.getHours() + (8 + date.getTimezoneOffset() / 60);
  date.setHours(GMT_8);
  let formatted_date = "";
  switch (formate) {
    case "YYYY-MM-DD hh:mm":
      formatted_date =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes());
      break;
    case "YYYY-MM-DD":
      formatted_date =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      break;
    case "YYYY-MM":
      formatted_date = date.getFullYear() + "-" + (date.getMonth() + 1);
      break;
  }
  return formatted_date;
}
