<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  ref,
  computed,
  watch,
} from "vue";
import Chip from "primevue/chip";
import Dropdown from "primevue/dropdown";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Calendar from "primevue/calendar";
import Column from "primevue/column";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import ProductOrder from "../api/salary/line/user/product_order";
import ProductOrderContent from "../api/salary/line/user/product_order_content";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import { dateFormate } from "../tool/formate";
import Auth from "../api/salary/line/auth";
let product_orderAPI = new ProductOrder();
let product_order_contentAPI = new ProductOrderContent();
let authAPI = new Auth();
import router from "../router";
const store = useStore();
const expandedRows = ref([]);
const i18n = useI18n();
const toast = useToast();
const data = reactive({
  selectStatusList: [
    {
      id: 1,
      name: i18n.$t("ProductOrder").Status.Establish,
    },
    {
      id: 2,
      name: i18n.$t("ProductOrder").Status.Action,
    },
    {
      id: 3,
      name: i18n.$t("ProductOrder").Status.Waiting,
    },
    {
      id: 4,
      name: i18n.$t("ProductOrder").Status.Complete,
    },
    {
      id: 5,
      name: i18n.$t("ProductOrder").Status.Cancel,
    },
  ],
  product_orderLoading: false,
  totalRecords: 0,
  product_orders: { data: null },
  product_order: null,
  page: 1,
  page_num: 10,
  product_orderFilters: {
    line_user_user_id: { value: "", matchMode: "contains" },
    created_at_begin: { value: "", matchMode: "contains" },
    created_at_end: { value: "", matchMode: "contains" },
    delivery_date_begin: { value: "", matchMode: "contains" },
    delivery_date_end: { value: "", matchMode: "contains" },
    receiver_name: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  try {
    await liff.init({ liffId: "1657582670-19n5nQ2E" }); // Use own liffId
    if (!liff.isLoggedIn()) {
      await liff.login({ redirectUri: window.location.href });
    }
  } catch (err) {
    console.log(`liff.state init error ${err}`);
  }
  CheckUser();
});
const mounted = onMounted(async () => {
  data.product_orderLoading = true;
  // data.product_orderFilters.line_user_user_id.value =
  //   router.currentRoute._value.query.user_id;
  getProductOrderList();
});
function CheckUser() {
  authAPI.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      data.product_orderFilters.line_user_user_id.value = res.data.data.user_id;
      // store.commit("set_user", res.data.data);
    } else {
      Login();
    }
  });
}
async function Login() {
  try {
    if (!liff.isLoggedIn()) {
      await liff.login({ redirectUri: window.location.href });
    }
    let user = await liff.getProfile();
    authAPI
      .loginFrontendUser(
        user.userId,
        user.displayName,
        "hYorHuMkC5tziGicTJoCMLQZS"
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          CheckUser();
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  } catch (err) {
    console.log(`liff.state init error ${err}`);
  }
  // authAPI
  //   .loginUser(
  //     encodeURI(
  //       decodeURI(router.currentRoute._value.query.state).split(",")[1]
  //     ),
  //     // decodeURI(router.currentRoute._value.query.code)
  //     router.currentRoute._value.query.code
  //   )
  //   .then((res) => {
  //     if (res.data.error_code == 0) {
  //       CheckUser();
  //     } else {
  //       toast.add({
  //         severity: "warn",
  //         summary: "Warn Message",
  //         detail: res.data.error_text,
  //         life: 3000,
  //       });
  //     }
  //   });
}
function onRowExpand(event) {
  if (data.product_orders.data != null)
    for (let i = 0; i < data.product_orders.data.length; i++) {
      if (event.data.id == data.product_orders.data[i].id) {
        product_order_contentAPI
          .getProductOrderContentList(
            data.product_orderFilters.line_user_user_id.value,
            event.data.id,
            1,
            100
          )
          .then((res) => {
            if (res.data.error_code == 0) {
              data.product_orders.data[i].contents = res.data.data.datas;
              console.log(res.data.data.datas);
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          });
      }
    }
}
function getProductOrderList() {
  product_orderAPI
    .getProductOrderList(
      data.product_orderFilters.line_user_user_id.value,
      data.product_orderFilters.created_at_begin.value,
      data.product_orderFilters.created_at_end.value,
      data.product_orderFilters.delivery_date_begin.value,
      data.product_orderFilters.delivery_date_end.value,
      data.product_orderFilters.receiver_name.value,
      data.product_orderFilters.status.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.product_orders.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.product_orderLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.product_orderLoading = false;
      }
    });
}

function onPage(event) {
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.product_orderLoading = true;
    getProductOrderList();
  }
);
watch(
  () => data.product_orderFilters.receiver_name.value,
  () => {
    data.product_orderLoading = true;
    getProductOrderList();
  }
);
watch(
  () => data.StatusSearch,
  () => {
    data.product_orderLoading = true;
    if (data.StatusSearch != null) {
      data.product_orderFilters.status.value =
        data.StatusSearch == null ? "" : data.StatusSearch.id;
    } else {
      data.product_orderFilters.status.value = "";
    }
    getProductOrderList();
  }
);
watch(
  () => data.delivery_date,
  () => {
    data.product_orderLoading = true;
    if (data.delivery_date != null) {
      data.product_orderFilters.delivery_date_begin.value =
        data.delivery_date[0] != null
          ? data.delivery_date[0].toISOString()
          : "";
      data.product_orderFilters.delivery_date_end.value =
        data.delivery_date[1] != null
          ? data.delivery_date[1].toISOString()
          : "";
    } else {
      data.product_orderFilters.delivery_date_begin.value = "";
      data.product_orderFilters.delivery_date_end.value = "";
    }

    getProductOrderList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      :expander="true"
      columnResizeMode="fit"
      :value="data.product_orders.data"
      :loading="data.product_orderLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 40px)"
      style="min-width: 375px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.product_orderFilters"
      v-model:selection="data.selectedProductTypes"
      v-model:expandedRows="expandedRows"
      :row-hover="true"
      @rowExpand="onRowExpand"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{
                  i18n.$t("ProductType").ProductTypeTable.Search
                }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.product_orderFilters[
                        i18n.$t('ProductOrder').ProductOrderTable.ReceiverName
                          .Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('ProductOrder').ProductOrderTable.ReceiverName
                        .Show
                    "
                  />
                </span>
                <br />
                <span class="p-input-icon-left p-mt-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    class=""
                    v-model="data.StatusSearch"
                    :options="data.selectStatusList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Product').ProductTable.Status.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </p>
              <!-- <div class="p-col-12" style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.delivery_date"
                    selectionMode="range"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('ProductOrder').ProductOrderTable.DeliveryDate
                        .Show
                    "
                    :showButtonBar="true"
                  />
                </span>
              </div> -->
            </AccordionTab>
          </Accordion>
        </div>
      </template>
      <Column
        :expander="true"
        headerStyle="width: 3rem"
        bodyStyle="white-space: nowrap;width:10px;  overflow: scroll"
      />
      <Column
        class="p-col-1"
        bodyStyle="white-space: nowrap;  overflow: scroll"
        :field="i18n.$t('ProductOrder').ProductOrderTable.ID.Field"
        :header="i18n.$t('ProductOrder').ProductOrderTable.ID.Show"
      ></Column>
      <!-- <Column
        bodyStyle="white-space: nowrap; overflow: scroll"
        :field="i18n.$t('ProductOrder').ProductOrderTable.ReceiverName.Field"
        :header="i18n.$t('ProductOrder').ProductOrderTable.ReceiverName.Show"
      ></Column> -->
      <!-- <Column
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
        :field="i18n.$t('ProductOrder').ProductOrderTable.Address.Field"
        :header="i18n.$t('ProductOrder').ProductOrderTable.Address.Show"
      ></Column> -->
      <!-- <Column
        :header="i18n.$t('ProductOrder').ProductOrderTable.DeliveryDate.Show"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      >
        <template #body="slotProps">
          {{
            slotProps.data.delivery_date == ""
              ? i18n.$t("ProductOrder").UnKnow
              : dateFormate(slotProps.data.delivery_date, "YYYY-MM-DD")
          }}
        </template>
      </Column> -->
      <Column
        :header="i18n.$t('ProductOrder').ProductOrderTable.TotalDollar.Show"
        bodyStyle="white-space: nowrap;  overflow: scroll"
      >
        <template #body="slotProps">
          {{ slotProps.data.total_settlement_dollar }}
        </template>
      </Column>
      <!-- <Column
        class="p-col-2"
        bodyStyle="white-space: nowrap;  overflow: scroll"
        :header="i18n.$t('ProductOrder').ProductOrderTable.PaymentStatus.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.payment_status == 1
              ? i18n.$t("ProductOrder").PaymentStatus.NoPayment
              : slotProps.data.payment_status == 2
              ? i18n.$t("ProductOrder").PaymentStatus.InternetCard
              : slotProps.data.payment_status == 3
              ? i18n.$t("ProductOrder").PaymentStatus.InternetRemittance
              : slotProps.data.payment_status == 4
              ? i18n.$t("ProductOrder").PaymentStatus.StorePayment
              : slotProps.data.payment_status == 5
              ? i18n.$t("ProductOrder").PaymentStatus.CashOnDelivery
              : slotProps.data.payment_status == 6
              ? i18n.$t("ProductOrder").PaymentStatus.PaymentCompleted
              : i18n.$t("ProductOrder").PaymentStatus.UnKnow
          }}
        </template>
      </Column> -->
      <Column
        class="p-col-2"
        bodyStyle="white-space: nowrap;overflow: scroll"
        :header="i18n.$t('ProductOrder').ProductOrderTable.Status.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.status == 1
              ? i18n.$t("ProductOrder").Status.Establish
              : slotProps.data.status == 2
              ? i18n.$t("ProductOrder").Status.Action
              : slotProps.data.status == 3
              ? i18n.$t("ProductOrder").Status.Waiting
              : slotProps.data.status == 4
              ? i18n.$t("ProductOrder").Status.Complete
              : slotProps.data.status == 5
              ? i18n.$t("ProductOrder").Status.Cancel
              : i18n.$t("ProductOrder").Status.UnKnow
          }}
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="orders-subtable">
          <h5>
            {{ i18n.$t("ProductOrder").ProductOrderTable.ReceiverName.Show }}:
            {{ slotProps.data.receiver_name }}
            <br />
            {{ i18n.$t("ProductOrder").ProductOrderTable.Dollar.Show }}:
            {{ slotProps.data.total_dollar }}<br />
            {{ i18n.$t("ProductOrder").ProductOrderTable.Discount.Show }}:
            {{ slotProps.data.total_add_sub_dollar }}
            <Chip
              v-for="item in slotProps.data.product_order_add_sub_records"
              :key="item.add_sub_name"
              class="ip-mr-1"
            >
              {{ item.add_sub_name }}
            </Chip>
            <br />
            <span v-if="slotProps.data.address != ''">
              {{ i18n.$t("ProductOrder").ProductOrderTable.Address.Show }}:
              {{ slotProps.data.address }}<br />
            </span>
            <span v-if="slotProps.data.predict_delivery_date != ''">
              {{
                i18n.$t("ProductOrder").ProductOrderTable.PredictDeliveryDate
                  .Show
              }}:
              {{
                slotProps.data.predict_delivery_date == ""
                  ? i18n.$t("ProductOrder").UnKnow
                  : dateFormate(
                      slotProps.data.predict_delivery_date,
                      "YYYY-MM-DD hh:mm"
                    )
              }}<br />
            </span>
            <span v-if="slotProps.data.delivery_date != ''">
              {{ i18n.$t("ProductOrder").ProductOrderTable.DeliveryDate.Show }}:
              {{
                slotProps.data.delivery_date == ""
                  ? i18n.$t("ProductOrder").UnKnow
                  : dateFormate(
                      slotProps.data.delivery_date,
                      "YYYY-MM-DD hh:mm"
                    )
              }}<br />
            </span>
            {{ i18n.$t("ProductOrder").ProductOrderTable.CreatedAt.Show }}:
            {{
              slotProps.data.created_at == ""
                ? i18n.$t("ProductOrder").UnKnow
                : dateFormate(slotProps.data.created_at, "YYYY-MM-DD")
            }}<br />
            {{ i18n.$t("ProductOrder").ProductOrderTable.PaymentMethod.Show }}:
            {{
              slotProps.data.payment_method == 2
                ? i18n.$t("ProductOrder").PaymentStatus.InternetCard
                : slotProps.data.payment_method == 3
                ? i18n.$t("ProductOrder").PaymentStatus.InternetRemittance
                : slotProps.data.payment_method == 4
                ? i18n.$t("ProductOrder").PaymentStatus.StorePayment
                : slotProps.data.payment_method == 5
                ? i18n.$t("ProductOrder").PaymentStatus.CashOnDelivery
                : i18n.$t("ProductOrder").PaymentStatus.UnKnow
            }}
            <br />
            {{ i18n.$t("ProductOrder").ProductOrderTable.PaymentStatus.Show }}:
            {{
              slotProps.data.payment_status == 1
                ? i18n.$t("ProductOrder").PaymentStatus.NoPayment
                : slotProps.data.payment_status == 2
                ? i18n.$t("ProductOrder").PaymentStatus.PaymentCompleted
                : i18n.$t("ProductOrder").PaymentStatus.UnKnow
            }}
            <span v-if="slotProps.data.remark != ''">
              <br />
              {{ i18n.$t("ProductOrder").ProductOrderTable.Remark.Show }}:
              <span
                style="
                  overflow: auto;
                  width: 275px;
                  overflow-wrap: break-word;
                  display: inline-block;
                  line-height: 0.7;
                "
                >{{ slotProps.data.remark }}</span
              >
            </span>
          </h5>
          <DataTable
            style="width: 90vw"
            :value="slotProps.data.contents"
            responsiveLayout="scroll"
          >
            <Column
              bodyStyle="white-space: nowrap;  overflow: auto"
              :field="
                i18n.$t('ProductOrder').ProductOrderContentTable.Name.Field
              "
              :header="
                i18n.$t('ProductOrder').ProductOrderContentTable.Name.Show
              "
            ></Column>
            <Column
              :header="
                i18n.$t('ProductOrder').ProductOrderContentTable.Image.Show
              "
            >
              <template #body="ContentslotProps">
                <img
                  class="product-image"
                  :src="ContentslotProps.data.product_image_url"
                />
              </template>
            </Column>
            <Column
              :field="
                i18n.$t('ProductOrder').ProductOrderContentTable.Count.Field
              "
              :header="
                i18n.$t('ProductOrder').ProductOrderContentTable.Count.Show
              "
            ></Column>
            <Column
              :field="
                i18n.$t('ProductOrder').ProductOrderContentTable.Dollar.Field
              "
              :header="
                i18n.$t('ProductOrder').ProductOrderContentTable.Dollar.Show
              "
            ></Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<style scoped>
.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
