import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "buttersea",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class ProductType {
  getProductTypeInfo(id) {
    return axios.get(
      `/api/admin/product-type/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getProductTypeList(id, name, page, page_num) {
    return axios.get(
      `/api/admin/product-type/list`,
      {
        params: {
          id: id,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getProductTypeSelectList(name) {
    return axios.get(
      `/api/admin/product-type/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  batchProductType(datas) {
    return axios.post(
      `/api/admin/product-type/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addProductType(name, description) {
    return axios.post(
      `/api/admin/product-type/`,
      {
        name: name,
        description: description,
      },
      config
    );
  }
  editProductType(id, name, description) {
    return axios.put(
      `/api/admin/product-type/`,
      {
        id: id,
        name: name,
        description: description,
      },
      config
    );
  }
  deleteProductType(id) {
    return axios.delete(
      `/api/admin/product-type/`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
