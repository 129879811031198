<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  ref,
} from "vue";
import Chip from "primevue/chip";
import Checkbox from "primevue/checkbox";
import Calendar from "primevue/calendar";
import Image from "primevue/image";
import TabMenu from "primevue/tabmenu";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Toolbar from "primevue/toolbar";
import Card from "primevue/card";
import Skeleton from "primevue/skeleton";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import FileUpload from "primevue/fileUpload";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Dropdown from "primevue/dropdown";
import Toast from "primevue/toast";
import Button from "primevue/button";
import DataView from "primevue/dataview";
// import Product from "../api/salary/admin/product";
import ProductType from "../api/salary/line/product_type";
import NormalProduct from "../api/salary/line/product";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
// import User from "../api/salary/auth/user";
import firebase from "firebase";
import router from "../router";
import Divider from "primevue/divider";
import { getBase64 } from "../tool/image";

import StoreInformation from "../api/salary/line/store_information";
import Auth from "../api/salary/line/auth";
import ProductOrder from "../api/salary/line/user/product_order";
import Sidebar from "primevue/sidebar";
// let userApi = new User();

let store_informationAPI = new StoreInformation();
let authAPI = new Auth();
let product_orderAPI = new ProductOrder();

// let productAPI = new Product();
let product_typeAPI = new ProductType();
let normal_productAPI = new NormalProduct();

const store = useStore();

function CheckUser() {
  authAPI.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      //   console.log(res.data.data);
      // store.commit("set_user", res.data.data);
      //   data.customer = res.data.data;
      data.add_product_order.orderer_name = res.data.data.name;
      data.add_product_order.orderer_phone = res.data.data.phone;
      getProductList();
    } else {
      Login();
    }
  });
}

function AddFriend() {
  data.add_friend = false;
  window.open(data.add_friend_url);
}

function FinishOrder() {
  let tmp = data.content_list;
  for (let i = 0; i < tmp.length; i++) {
    tmp[i].total_count = String(tmp[i].total_count);
  }
  if (data.add_product_order.payment_method != "") {
    var regex = new RegExp(/^([0-9]{5}).*/);
    if (data.add_product_order.payment_method == 3) {
      if (regex.test(data.add_product_order.remark)) {
        product_orderAPI
          .addProductOrder(
            //   data.add_product_order.orderer_name,
            "",
            data.add_product_order.orderer_phone,
            data.checked == true ? "" : data.add_product_order.receiver_name,
            data.checked == true
              ? data.add_product_order.orderer_phone
              : data.add_product_order.receiver_phone,
            data.add_product_order.predict_delivery_date,
            data.add_product_order.predict_delivery_date,
            data.content_active == 0 ? data.add_product_order.address : "",
            data.add_product_order.remark,
            2,
            data.add_product_order.payment_status,
            data.add_product_order.payment_method,
            tmp
          )
          .then((res) => {
            if (res.data.error_code == 0) {
              console.log(res.data.data);
              data.save_add_cartDialog = false;
              data.content_total = 0;
              data.sub_total = 0;
              data.content_list = [];
              data.content_active = 0;
              data.PaymentStatusAddSearch = "";
              data.PaymentMethodAddSearch = "";
              data.add_product_order = {
                id: "",
                orderer_name: data.add_product_order.orderer_name,
                orderer_phone: data.add_product_order.orderer_phone,
                receiver_name: "",
                receiver_phone: "",
                delivery_date: "",
                predict_delivery_date: "",
                address: "",
                remark: "",
                source_of_order: "",
                payment_status: 1,
                payment_method: 0,
              };
              toast.add({
                severity: "success",
                summary: "Success Message",
                detail: i18n.$t("ProductOrder").AddSuccess,
                life: 3000,
              });
              getProductList();
              if (!liff.isLoggedIn()) {
                liff.login({ redirectUri: window.location.href });
              } else {
                liff.getFriendship().then((check) => {
                  console.log(check);
                  if (!check.friendFlag) {
                    data.add_friend = true;
                  }
                });
              }
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          });
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail:
            i18n.$t("Product").ProductTable.Enter +
            i18n.$t("Product").ProductTable.BankCode,
          life: 3000,
        });
      }
    } else {
      product_orderAPI
        .addProductOrder(
          //   data.add_product_order.orderer_name,
          "",
          data.add_product_order.orderer_phone,
          data.checked == true ? "" : data.add_product_order.receiver_name,
          data.checked == true
            ? data.add_product_order.orderer_phone
            : data.add_product_order.receiver_phone,
          data.add_product_order.predict_delivery_date,
          data.add_product_order.predict_delivery_date,
          data.content_active == 0 ? data.add_product_order.address : "",
          data.add_product_order.remark,
          2,
          data.add_product_order.payment_status,
          data.add_product_order.payment_method,
          tmp
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            console.log(res.data.data);
            data.save_add_cartDialog = false;
            data.content_total = 0;
            data.sub_total = 0;
            data.content_list = [];
            data.content_active = 0;
            data.PaymentStatusAddSearch = "";
            data.PaymentMethodAddSearch = "";
            data.add_product_order = {
              id: "",
              orderer_name: data.add_product_order.orderer_name,
              orderer_phone: data.add_product_order.orderer_phone,
              receiver_name: "",
              receiver_phone: "",
              delivery_date: "",
              predict_delivery_date: "",
              address: "",
              remark: "",
              source_of_order: "",
              payment_status: 1,
              payment_method: 0,
            };
            toast.add({
              severity: "success",
              summary: "Success Message",
              detail: i18n.$t("ProductOrder").AddSuccess,
              life: 3000,
            });
            getProductList();
            if (!liff.isLoggedIn()) {
              liff.login({ redirectUri: window.location.href });
            } else {
              liff.getFriendship().then((check) => {
                console.log(check);
                if (!check.friendFlag) {
                  data.add_friend = true;
                }
              });
            }
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
    }
  } else {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail:
        i18n.$t("Product").ProductTable.Enter +
        i18n.$t("ProductOrder").ProductOrderTable.PaymentStatus.Show,
      life: 3000,
    });
  }
}

async function Login() {
  try {
    if (!liff.isLoggedIn()) {
      await liff.login({ redirectUri: window.location.href });
    }
    let user = await liff.getProfile();
    authAPI
      .loginFrontendUser(
        user.userId,
        user.displayName,
        "hYorHuMkC5tziGicTJoCMLQZS"
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          CheckUser();
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  } catch (err) {
    console.log(`liff.state init error ${err}`);
  }
  // if (
  //   decodeURI(router.currentRoute._value.query.state).split(",")[1] ==
  //   "customer"
  // ) {
  //   try {
  //     if (!liff.isLoggedIn()) {
  //       await liff.login({ redirectUri: window.location.href });
  //     }
  //     let user = await liff.getProfile();
  //     authAPI
  //       .loginUser(user.userId, user.displayName, "hYorHuMkC5tziGicTJoCMLQZS")
  //       .then((res) => {
  //         if (res.data.error_code == 0) {
  //           CheckUser();
  //         } else {
  //           toast.add({
  //             severity: "warn",
  //             summary: "Warn Message",
  //             detail: res.data.error_text,
  //             life: 3000,
  //           });
  //         }
  //       });
  //   } catch (err) {
  //     console.log(`liff.state init error ${err}`);
  //   }
  // } else {
  //   authAPI
  //     .loginUser(
  //       encodeURI(
  //         decodeURI(router.currentRoute._value.query.state).split(",")[1]
  //       ),
  //       // decodeURI(router.currentRoute._value.query.code)
  //       router.currentRoute._value.query.code
  //     )
  //     .then((res) => {
  //       if (res.data.error_code == 0) {
  //         CheckUser();
  //       } else {
  //         toast.add({
  //           severity: "warn",
  //           summary: "Warn Message",
  //           detail: res.data.error_text,
  //           life: 3000,
  //         });
  //       }
  //     });
  // }
}

async function sendTargetPicker(item) {
  if (!liff.isLoggedIn()) {
    liff.login({ redirectUri: window.location.href });
  }
  if (liff.isApiAvailable("shareTargetPicker")) {
    try {
      // console.log(data.domain_name + item.image_url);
      // console.log(item.name);
      // console.log(item.description);
      let uri =
        "https://liff.line.me/1657582670-0wRYRdmJ?image_url=" +
        item.image_url +
        "&name=" +
        item.name;
      // if (item.description != "") {
      //   uri = uri + "&description=" + item.description;
      // }
      const picker = await liff.shareTargetPicker([
        {
          type: "flex",
          altText: "好東西與您分享",
          contents: {
            type: "bubble",
            body: {
              type: "box",
              layout: "vertical",
              contents: [
                {
                  type: "image",
                  url: data.domain_name + item.image_url,
                  size: "full",
                  aspectMode: "fit",
                  aspectRatio: "1.75:3",
                  gravity: "top",
                  offsetBottom: "53px",
                },
                {
                  type: "box",
                  layout: "vertical",
                  contents: [
                    {
                      type: "box",
                      layout: "vertical",
                      contents: [
                        {
                          type: "text",
                          text: item.name,
                          size: "xl",
                          color: "#ffffff",
                          weight: "bold",
                        },
                      ],
                    },
                    // {
                    //   type: "box",
                    //   layout: "baseline",
                    //   contents: [
                    //     {
                    //       type: "text",
                    //       text: item.description,
                    //       color: "#ebebeb",
                    //       size: "sm",
                    //       flex: 0,
                    //       wrap: true,
                    //     },
                    //   ],
                    //   spacing: "lg",
                    // },
                    {
                      type: "box",
                      layout: "vertical",
                      contents: [
                        {
                          type: "filler",
                        },
                        {
                          type: "box",
                          layout: "baseline",
                          contents: [
                            {
                              type: "filler",
                            },
                            {
                              type: "text",
                              text: "快速購買",
                              color: "#ffffff",
                              flex: 0,
                              offsetTop: "-2px",
                            },
                            {
                              type: "filler",
                            },
                          ],
                          spacing: "sm",
                          action: {
                            type: "uri",
                            label: "快速購買",
                            uri: "https://liff.line.me/1657582670-18ZwZNrY",
                          },
                        },
                        {
                          type: "filler",
                        },
                      ],
                      borderWidth: "1px",
                      cornerRadius: "4px",
                      spacing: "sm",
                      borderColor: "#ffffff",
                      margin: "xxl",
                      height: "40px",
                    },
                  ],
                  position: "absolute",
                  offsetBottom: "0px",
                  offsetStart: "0px",
                  offsetEnd: "0px",
                  backgroundColor: "#03303Acc",
                  paddingAll: "20px",
                  paddingTop: "18px",
                },
                {
                  type: "box",
                  layout: "vertical",
                  contents: [
                    {
                      type: "text",
                      text: "每日推薦",
                      color: "#ffffff",
                      align: "center",
                      size: "xs",
                      offsetTop: "3px",
                      wrap: true,
                    },
                  ],
                  position: "absolute",
                  cornerRadius: "20px",
                  offsetTop: "18px",
                  backgroundColor: "#ff334b",
                  offsetStart: "18px",
                  height: "25px",
                  width: "100px",
                },
                {
                  type: "box",
                  layout: "vertical",
                  contents: [
                    {
                      type: "text",
                      text: "好友分享",
                      align: "center",
                      size: "xs",
                      offsetTop: "3px",
                      wrap: true,
                    },
                  ],
                  position: "absolute",
                  cornerRadius: "20px",
                  offsetTop: "18px",
                  backgroundColor: "#a6ed8e",
                  height: "25px",
                  width: "100px",
                  offsetEnd: "18px",
                  action: {
                    type: "uri",
                    label: "action",
                    uri: uri,
                  },
                },
              ],
              paddingAll: "0px",
            },
          },
        },
      ]);
      if (picker) {
        // succeeded in sending a message through TargetPicker
        console.log(`[${picker.status}] Message sent!`);
      } else {
        const [majorVer, minorVer] = (liff.getLineVersion() || "").split(".");
        if (parseInt(majorVer) == 10 && parseInt(minorVer) < 11) {
          console.log(
            "TargetPicker was opened at least. Whether succeeded to send message is unclear"
          );
        } else console.log("TargetPicker was closed!");
      }
    } catch (error) {
      // something went wrong before sending a message
      console.log(error);
      console.log("Flex Message got some error");
      liff.closeWindow();
    }
  } else console.log("Please login...");
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  tmp_content: [],
  add_friend_url:
    "https://liff.line.me/1645278921-kWRPP32q/?accountId=231aojkj",
  add_friend: false,
  //   order_total: 0,
  domain_name: "https://demolinebot.leyutechs.com/",
  open_weekly: [],
  store_information: {
    id: "",
    name: "",
    atm_account_number: "",
    atm_bank_number: "",
    line_start_time: "",
    line_end_time: "",
    entity_open_time_description: "",
    physical_address: "",
    open_weekly: "",
  },
  checked: false,
  selectPaymentMethodListForOut: [
    {
      id: 3,
      name: i18n.$t("ProductOrder").PaymentStatus.InternetRemittance,
    },
    {
      id: 4,
      name: i18n.$t("ProductOrder").PaymentStatus.StorePayment,
    },
    {
      id: 5,
      name: i18n.$t("ProductOrder").PaymentStatus.CashOnDelivery,
    },
  ],
  selectPaymentMethodList: [
    // {
    //   id: 1,
    //   name: i18n.$t("ProductOrder").PaymentStatus.NoPayment,
    // },
    // {
    //   id: 2,
    //   name: i18n.$t("ProductOrder").PaymentStatus.InternetCard,
    // },
    {
      id: 3,
      name: i18n.$t("ProductOrder").PaymentStatus.InternetRemittance,
    },
    {
      id: 4,
      name: i18n.$t("ProductOrder").PaymentStatus.StorePayment,
    },
    // {
    //   id: 5,
    //   name: i18n.$t("ProductOrder").PaymentStatus.CashOnDelivery,
    // },
    // {
    //   id: 6,
    //   name: i18n.$t("ProductOrder").PaymentStatus.PaymentCompleted,
    // },
  ],
  add_product_order: {
    id: "",
    orderer_name: "",
    orderer_phone: "",
    receiver_name: "",
    receiver_phone: "",
    delivery_date: "",
    predict_delivery_date: "",
    address: "",
    remark: "",
    source_of_order: "",
    payment_status: 1,
    payment_method: 0,
  },
  content_active: 0,
  save_add_cartDialog: false,
  content_total: 0,
  content_list: [],
  product_content: {
    id: "",
    name: "",
    code: "",
    dollar: 0,
    count: 0,
    image: "",
  },
  content_number: 0,
  add_cartDialog: false,
  active: 0,
  selectProductTypesList: [],
  selectStatusList: [
    {
      id: 1,
      name: i18n.$t("Product").Disable,
    },
    {
      id: 2,
      name: i18n.$t("Product").Enabled,
    },
    {
      id: 3,
      name: i18n.$t("Product").Freeze,
    },
  ],
  add_product: {
    id: "",
    name: "",
    code: "",
    dollar: 0,
    count: 0,
    image: "",
  },
  productLoading: false,
  totalRecords: 0,
  products: { data: null },
  product: null,
  page: 1,
  page_num: 100,
  selectedProducts: null,
  productFilters: {
    code: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
    type: { value: "", matchMode: "contains" },
    is_top: { value: "", matchMode: "contains" },
  },
  IsTopSearch: {
    id: 0,
    name: i18n.$t("Product").IsAllShow,
  },
  selectIsTopList: [
    {
      id: 0,
      name: i18n.$t("Product").IsAllShow,
    },
    // {
    //   id: 1,
    //   name: i18n.$t("Product").NoIsTopShow,
    // },
    {
      id: 2,
      name: i18n.$t("Product").IsTopShow,
    },
  ],
});

const beforemounted = onBeforeMount(async () => {
  try {
    await liff.init({ liffId: "1657582670-18ZwZNrY" }); // Use own liffId
    if (!liff.isLoggedIn()) {
      await liff.login({ redirectUri: window.location.href });
    }
  } catch (err) {
    console.log(`liff.state init error ${err}`);
  }
  CheckUser();
  //   if (router.currentRoute.value.fullPath != "/product-show") {
  //     CheckUser();
  //   }
});

const mounted = onMounted(async () => {
  data.productLoading = true;
  getProductTypeSelectList();
  getStoreInformation();
  // if (store.state.user != null) {
  //   getProductList();
  // }
});

function RemoveContent(product_id) {
  let p_index = data.content_list.findIndex((x) => x.product_id === product_id);
  data.content_list.splice(p_index, 1);
}
function AddCart(product) {
  data.product_content = product;
  data.add_cartDialog = true;
  data.content_number = 0;
  for (let i = 0; i < data.content_list.length; i++) {
    if (data.content_list[i].product_id == data.product_content.id) {
      data.content_number = data.content_list[i].total_count;
    }
  }
}
function SaveAddContent() {
  data.save_add_cartDialog = true;
  //   data.order_total = 0;
  //   for (let i = 0; i < data.content_list.length; i++) {
  //     data.order_total =
  //       data.order_total +
  //       data.content_list[i].total_count * data.content_list[i].product_dollar;
  //   }
}
function AddContent() {
  let p_index = data.content_list.findIndex(
    (x) => x.product_id === data.product_content.id
  );
  if (p_index == -1) {
    data.content_list.push({
      product_name: data.product_content.name,
      product_dollar: data.product_content.dollar,
      product_id: data.product_content.id,
      total_count: data.content_number,
      count: data.product_content.count,
      add_sub_lists: data.product_content.add_sub_lists,
    });
    // data.content_total =
    //   data.content_total + data.content_number * data.product_content.dollar;
  } else {
    // let n = data.content_number - data.content_list[p_index].total_count;

    data.content_list[p_index].total_count = data.content_number;
    // data.content_total = data.content_total + n * data.product_content.dollar;
  }
  data.add_cartDialog = false;
}
function getStoreInformation() {
  store_informationAPI.getStoreInformationInfo(1).then((res) => {
    if (res.data.error_code == 0) {
      data.store_information = res.data.data;
      let tmp = [];
      for (let i = 0; i < data.store_information.open_weekly.length; i++) {
        if (data.store_information.open_weekly[i] != 0) {
          if (data.store_information.open_weekly[i] == 7) {
            tmp.push(0);
          } else {
            tmp.push(data.store_information.open_weekly[i]);
          }
        }
      }
      for (let i = 0; i < 7; i++) {
        if (tmp.find((element) => element == i) == undefined) {
          data.open_weekly.push(i);
        }
      }
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getProductTypeSelectList() {
  product_typeAPI.getProductTypeSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectProductTypesList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getProductList() {
  normal_productAPI
    .getProductList(
      data.productFilters.code.value,
      data.productFilters.name.value,
      2,
      data.productFilters.is_top.value,
      data.productFilters.type.value != ""
        ? [data.productFilters.type.value]
        : [],
      //   [1, 2],
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.products.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.productLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.productLoading = false;
      }
    });
}
function handleScroll(e) {
  if (
    e.target.clientHeight + Math.ceil(e.target.scrollTop) >=
      e.target.scrollHeight &&
    data.page < Math.ceil(data.totalRecords / data.page_num)
  ) {
    data.productLoading = true;
    data.page = data.page + 1;
    getProductList();
  }
}
function Choose(product) {
  if (
    store.state.shop.filter((e) => e.id === product.id).length == 0 &&
    store.state.shop_already.filter((e) => e.product_id === product.id)
      .length == 0
  ) {
    product.count = 0;
    store.state.shop.push(product);
  }
}
function Remove(product) {
  for (var i = 0; i < store.state.shop.length; i++) {
    var obj = store.state.shop[i];

    if (obj.id == product.id) {
      store.state.shop.splice(i, 1);
    }
  }
}

// function onPage(event) {
//   console.log(event);
//   data.page = event.page + 1;
// }
watch(
  () => data.content_number,
  () => {
    if (data.content_number > data.product_content.count) {
      data.content_number = 0;
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: i18n.$t("Product").ProductTable.WrongNumber,
        life: 3000,
      });
    }
  }
);
watch(
  () => data.page,
  () => {
    data.productLoading = true;
    getProductList();
  }
);
watch(
  () => data.productFilters.name.value,
  () => {
    data.productLoading = true;
    getProductList();
  }
);
watch(
  () => data.productFilters.code.value,
  () => {
    data.productLoading = true;
    getProductList();
  }
);
watch(
  () => data.StatusSearch,
  () => {
    data.productLoading = true;
    if (data.StatusSearch != null) {
      data.productFilters.status.value =
        data.StatusSearch == null ? "" : data.StatusSearch.id;
    } else {
      data.productFilters.status.value = "";
    }
    getProductList();
  }
);
watch(
  () => data.IsTopSearch,
  () => {
    data.productFilters.is_top.value =
      data.IsTopSearch == null ? "" : data.IsTopSearch.id;
    getProductList();
  }
);
watch(
  () => data.active,
  () => {
    if (data.active == 0) {
      data.productFilters.type.value = "";
    } else {
      data.productFilters.type.value =
        data.selectProductTypesList[data.active - 1].id;
    }
    getProductList();
  }
);

watch(
  () => data.content_list,
  async () => {
    data.content_total = 0;
    data.sub_total = 0;
    data.tmp_content = [];
    for (let i = 0; i < data.content_list.length; i++) {
      await data.tmp_content.push({
        product_name: data.content_list[i].product_name,
        product_dollar: data.content_list[i].product_dollar,
        product_id: data.content_list[i].product_id,
        total_count: data.content_list[i].total_count,
        count: data.content_list[i].count,
        add_sub_lists: data.content_list[i].add_sub_lists,
      });
    }
    for (let i = 0; i < data.tmp_content.length; i++) {
      data.tmp_content[i].total_sub = 0;
      if (data.tmp_content[i].add_sub_lists != null)
        for (let j = 0; j < data.tmp_content[i].add_sub_lists.length; j++) {
          if (data.tmp_content[i].add_sub_lists[j].status == 2) {
            if (
              parseFloat(
                data.tmp_content[i].add_sub_lists[j].product_coupon.max_count
              ) > parseFloat(data.tmp_content[i].total_count) &&
              parseFloat(data.tmp_content[i].total_count) >=
                parseFloat(
                  data.tmp_content[i].add_sub_lists[j].product_coupon.min_count
                )
            ) {
              if (
                parseFloat(data.tmp_content[i].add_sub_lists[j].percent) != 0
              ) {
                data.tmp_content[i].total_sub =
                  data.tmp_content[i].total_sub +
                  parseFloat(data.tmp_content[i].total_count) *
                    parseFloat(data.tmp_content[i].product_dollar) *
                    parseFloat(data.tmp_content[i].add_sub_lists[j].percent) -
                  parseFloat(data.tmp_content[i].total_count) *
                    parseFloat(data.tmp_content[i].product_dollar);
              }
              data.tmp_content[i].total_sub =
                data.tmp_content[i].total_sub +
                parseFloat(data.tmp_content[i].add_sub_lists[j].dollar);
            }
          }
        }
      data.content_total =
        data.content_total +
        data.tmp_content[i].total_count * data.tmp_content[i].product_dollar;
      data.sub_total = data.sub_total + data.tmp_content[i].total_sub;
    }
  },
  { deep: true }
);

watch(
  () => data.PaymentMethodAddSearch,
  () => {
    data.add_product_order.payment_method =
      data.PaymentMethodAddSearch == null ? "" : data.PaymentMethodAddSearch.id;
  }
);
const deliver_1 = ref();
const deliver_2 = ref();

function closeCalendar(item) {
  switch (item) {
    case "deliver_1":
      deliver_1.value.overlayVisible = false;
      break;
    case "deliver_2":
      deliver_2.value.overlayVisible = false;
      break;
  }
}
function todayCalendar(item) {
  let date = new Date();
  let dateMeta = {
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
    otherMonth:
      date.getMonth() !== this.currentMonth ||
      date.getFullYear() !== this.currentYear,
    today: true,
    selectable: true,
  };
  switch (item) {
    case "deliver_1":
      deliver_1.value.onDateSelect(null, dateMeta);
      deliver_1.value.$emit("today-click", date);
      break;
    case "deliver_2":
      deliver_2.value.onDateSelect(null, dateMeta);
      deliver_2.value.$emit("today-click", date);
      break;
  }
}
function checkStatus(item) {
  let check = false;
  if (item != null)
    for (let i = 0; i < item.length; i++) {
      if (item.status == 2) {
        check = true;
      }
    }
  return check;
}
</script>

<template>
  <Toast />
  <Toolbar class="product-toolbar">
    <template #start>
      <Dropdown
        style="
          font-size: 20px !important;
          width: 80px;
          border: 0px !important;
          font-weight: bold !important;
        "
        v-model="data.IsTopSearch"
        :options="data.selectIsTopList"
        optionLabel="name"
        :filter="false"
        :placeholder="i18n.$t('Product').ProductTable.IsTop.Show"
      >
        <template #value="slotProps">
          <div class="country-item country-item-value" v-if="slotProps.value">
            <div>{{ slotProps.value.name }}</div>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          <div class="country-item">
            <div>{{ slotProps.option.name }}</div>
          </div>
        </template>
      </Dropdown>
      <Divider
        layout="vertical"
        style="width: 1px; background-color: #c4c4c4"
      />
    </template>
    <template #end
      ><span style="font-size: 12px">{{
        i18n.$t("Product").EnterLogo
      }}</span></template
    >
  </Toolbar>
  <TabView class="product-type" v-model:activeIndex="data.active">
    <TabPanel header="全部"></TabPanel>
    <TabPanel
      :key="index"
      v-for="(type, index) in data.selectProductTypesList"
      :header="type.name"
    >
    </TabPanel>
  </TabView>
  <div class="" style="margin-top: 150px">
    <DataView :value="data.products.data" layout="list">
      <template #list="slotProps">
        <div class="p-col-12 p-text-left">
          <div class="product-list-item">
            <Image
              :src="slotProps.data.image_url"
              :alt="slotProps.data.name"
              preview
            />
            <!-- <img :src="slotProps.data.image_url" :alt="slotProps.data.name" /> -->
            <div class="product-list-detail">
              <div class="product-name">{{ slotProps.data.name }}</div>
              <div class="product-description">
                {{ i18n.$t("Product").ProductTable.Code.Show }}:
                {{ slotProps.data.code }}
                <!-- <Chip
                  v-for="item in slotProps.data.product_types"
                  :key="item.id"
                  class="p-mr-1"
                >
                  {{ item.name }}
                </Chip> -->
              </div>
              <div v-if="slotProps.data.is_top == 2">
                <i class="pi pi-heart-fill" style="font-size: 1rem"></i>
                {{ i18n.$t("Product").ProductTable.IsTop.Show }}
              </div>
              <!-- <Rating
              :modelValue="slotProps.data.rating"
              :readonly="true"
              :cancel="false"
            ></Rating> -->
              <i class="pi pi-tag product-category-icon"></i
              ><Chip
                v-for="item in slotProps.data.product_types"
                :key="item.id"
                class="p-mr-1"
              >
                {{ item.name }}
              </Chip>
              <br />
              <i
                v-if="checkStatus(slotProps.data.add_sub_lists)"
                class="pi pi-money-bill product-category-icon p-mt-1"
              ></i
              ><Chip
                v-for="item in slotProps.data.add_sub_lists"
                :key="item.id"
                :class="item.status == 2 ? 'p-mr-1 p-mt-1' : 'p-d-none'"
              >
                {{ item.name }}
              </Chip>
              <!-- <span class="product-category">{{ slotProps.data.category }}</span> -->
            </div>
            <div class="product-list-action">
              <span class="product-price">${{ slotProps.data.dollar }}</span>
              <span
                :class="
                  slotProps.data.count == 0
                    ? 'product-badge status-outofstock'
                    : 'product-badge status-instock'
                "
                >{{
                  slotProps.data.count == 0
                    ? i18n.$t("Product").ProductTable.OutOfStock
                    : i18n.$t("Product").ProductTable.InStock +
                      ":" +
                      slotProps.data.count
                }}</span
              >
              <div>
                <Button
                  class="p-mt-1 p-mr-2"
                  icon="pi pi-shopping-cart"
                  @click="AddCart(slotProps.data)"
                  :disabled="slotProps.data.count === 0"
                ></Button>
                <Button
                  class="p-mt-1 p-button-info"
                  icon="pi pi-reply"
                  @click="sendTargetPicker(slotProps.data)"
                  :disabled="slotProps.data.count === 0"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </DataView>
    <div class="footer">
      <!-- <Button
        v-if="data.content_total == 0"
        class="p-mb-2 p-button-info p-pt-2 p-pb-2"
        style="width: 95%"
        :label="i18n.$t('Product').ProductTable.Order"
      /> -->
      <Button
        v-if="data.content_total != 0"
        class="p-mb-2 p-button-info p-pt-2 p-pb-2"
        style="width: 95%"
        :label="i18n.$t('Product').ProductTable.Car"
        @click="SaveAddContent"
        :badge="'$' + (data.content_total + data.sub_total)"
      />
    </div>
  </div>
  <Sidebar v-model:visible="data.save_add_cartDialog" position="full">
    <div style="border-bottom: 1px solid gray" class="header">
      <h3 style="color: black">
        {{ i18n.$t("Product").ProductTable.OrderContent }}
      </h3>
      <Button
        icon="pi pi-truck"
        :class="
          data.content_active == 0
            ? 'p-mb-2 p-mt-2 p-button-outlined p-button-raised p-button-success p-button-rounded p-pt-2 p-pb-2 p-mr-2'
            : 'p-mb-2 p-mt-2 p-button-outlined p-button-raised p-button-secondary p-button-rounded p-pt-2 p-pb-2 p-mr-2'
        "
        style="width: 45%"
        :label="i18n.$t('Product').ProductTable.Delivery"
        @click="data.content_active = 0"
      />
      <Button
        icon="pi pi-gift"
        :class="
          data.content_active == 1
            ? 'p-mb-2 p-mt-2 p-button-outlined p-button-raised p-button-success p-button-rounded p-pt-2 p-pb-2'
            : 'p-mb-2 p-mt-2 p-button-outlined p-button-raised p-button-secondary p-button-rounded p-pt-2 p-pb-2'
        "
        style="width: 45%"
        :label="i18n.$t('Product').ProductTable.Takeaway"
        @click="data.content_active = 1"
      />
    </div>
    <div class="nav" style="margin-top: 114px; padding-bottom: 59px">
      <TabView v-model:activeIndex="data.content_active">
        <TabPanel>
          <div class="p-fluid">
            <!-- <div class="p-field">
              <label for="name">{{
                i18n.$t("ProductOrder").ProductOrderTable.OrdererName.Show
              }}</label>
              <InputText
                id="name"
                v-model.trim="data.add_product_order.orderer_name"
                required="true"
              />
            </div> -->
            <div class="p-field">
              <label for="name">{{
                i18n.$t("ProductOrder").ProductOrderTable.OrdererPhone.Show
              }}</label>
              <InputText
                id="name"
                v-model.trim="data.add_product_order.orderer_phone"
                required="true"
              />
            </div>
            <div class="field-checkbox">
              <span style="float: right">
                <Checkbox
                  inputId="binary"
                  v-model="data.checked"
                  :binary="true"
                />
                <label for="binary">{{
                  i18n.$t("Product").ProductTable.Same
                }}</label>
              </span>
              <br />
            </div>
            <div v-if="data.checked == false" class="p-field">
              <label for="name">{{
                i18n.$t("ProductOrder").ProductOrderTable.ReceiverName.Show
              }}</label>
              <InputText
                id="name"
                v-model.trim="data.add_product_order.receiver_name"
                required="true"
              />
            </div>
            <div v-if="data.checked == false" class="p-field">
              <label for="name">{{
                i18n.$t("ProductOrder").ProductOrderTable.ReceiverPhone.Show
              }}</label>
              <InputText
                id="name"
                v-model.trim="data.add_product_order.receiver_phone"
                required="true"
              />
            </div>
            <div class="p-field">
              <label for="name">{{
                i18n.$t("ProductOrder").ProductOrderTable.Address.Show
              }}</label>
              <InputText
                id="name"
                v-model.trim="data.add_product_order.address"
                required="true"
              />
            </div>
            <div class="p-field">
              <label for="name">{{
                i18n.$t("ProductOrder").ProductOrderTable.PredictDeliveryDate
                  .Show
              }}</label>
              <Calendar
                id="range"
                :touchUI="true"
                :showTime="true"
                :minDate="
                  new Date(data.store_information.line_start_time).getTime() <=
                  new Date().getTime()
                    ? new Date()
                    : new Date(data.store_information.line_start_time)
                "
                :maxDate="new Date(data.store_information.line_end_time)"
                :disabledDays="data.open_weekly"
                v-model="data.add_product_order.predict_delivery_date"
                :manualInput="false"
                :showIcon="true"
                :placeholder="
                  i18n.$t('ProductOrder').ProductOrderTable.PredictDeliveryDate
                    .Show
                "
                ref="deliver_1"
              >
                <template #footer>
                  <div class="p-datepicker-buttonbar">
                    <Button
                      @click="todayCalendar('deliver_1')"
                      class="p-button p-component p-button-text"
                      >Today</Button
                    >
                    <Button
                      @click="closeCalendar('deliver_1')"
                      class="p-button p-component p-button-text"
                      >Submit</Button
                    >
                  </div>
                </template>
              </Calendar>
            </div>
            <div class="p-field">
              <label for="name">{{
                i18n.$t("ProductOrder").ProductOrderTable.PaymentMethod.Show
              }}</label>
              <Dropdown
                v-model="data.PaymentMethodAddSearch"
                :options="data.selectPaymentMethodListForOut"
                optionLabel="name"
                :filter="true"
                :placeholder="
                  i18n.$t('ProductOrder').ProductOrderTable.PaymentMethod.Show
                "
                :showClear="true"
              >
                <template #value="slotProps">
                  <div
                    class="country-item country-item-value"
                    v-if="slotProps.value"
                  >
                    <div>{{ slotProps.value.name }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="country-item">
                    <div>{{ slotProps.option.name }}</div>
                  </div>
                </template>
              </Dropdown>
            </div>
            <div class="p-field">
              <label for="name"
                >{{ i18n.$t("ProductOrder").ProductOrderTable.Remark.Show }}(
                <span style="color: red">{{
                  i18n.$t("Product").ProductTable.Remark
                }}</span
                >)
              </label>
              <Textarea
                id="description"
                v-model="data.add_product_order.remark"
                required="true"
                rows="3"
                cols="20"
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel class="p-fluid">
          <div class="p-fluid">
            <!-- <div class="p-field">
              <label for="name">{{
                i18n.$t("ProductOrder").ProductOrderTable.OrdererName.Show
              }}</label>
              <InputText
                id="name"
                v-model.trim="data.add_product_order.orderer_name"
                required="true"
              />
            </div> -->
            <div class="p-field">
              <label for="name">{{
                i18n.$t("ProductOrder").ProductOrderTable.OrdererPhone.Show
              }}</label>
              <InputText
                id="name"
                v-model.trim="data.add_product_order.orderer_phone"
                required="true"
              />
            </div>
            <div class="field-checkbox">
              <span style="float: right">
                <Checkbox
                  inputId="binary"
                  v-model="data.checked"
                  :binary="true"
                />
                <label for="binary">{{
                  i18n.$t("Product").ProductTable.Same
                }}</label>
              </span>
              <br />
            </div>
            <div v-if="data.checked == false" class="p-field">
              <label for="name"
                >{{
                  i18n.$t("ProductOrder").ProductOrderTable.ReceiverName.Show
                }}
              </label>
              <InputText
                id="name"
                v-model.trim="data.add_product_order.receiver_name"
                required="true"
              />
            </div>
            <div v-if="data.checked == false" class="p-field">
              <label for="name">{{
                i18n.$t("ProductOrder").ProductOrderTable.ReceiverPhone.Show
              }}</label>
              <InputText
                id="name"
                v-model.trim="data.add_product_order.receiver_phone"
                required="true"
              />
            </div>
            <div class="p-field">
              <label for="name">{{
                i18n.$t("ProductOrder").ProductOrderTable.PredictDeliveryDate
                  .Show
              }}</label>
              <Calendar
                id="range"
                :touchUI="true"
                :showTime="true"
                :minDate="
                  new Date(data.store_information.line_start_time).getTime() <=
                  new Date().getTime()
                    ? new Date()
                    : new Date(data.store_information.line_start_time)
                "
                :maxDate="new Date(data.store_information.line_end_time)"
                :disabledDays="data.open_weekly"
                v-model="data.add_product_order.predict_delivery_date"
                :manualInput="false"
                :showIcon="true"
                :placeholder="
                  i18n.$t('ProductOrder').ProductOrderTable.PredictDeliveryDate
                    .Show
                "
                ref="deliver_2"
              >
                <template #footer>
                  <div class="p-datepicker-buttonbar">
                    <Button
                      @click="todayCalendar('deliver_2')"
                      class="p-button p-component p-button-text"
                      >Today</Button
                    >
                    <Button
                      @click="closeCalendar('deliver_2')"
                      class="p-button p-component p-button-text"
                      >Submit</Button
                    >
                  </div>
                </template>
              </Calendar>
            </div>
            <div class="p-field">
              <label for="name">{{
                i18n.$t("ProductOrder").ProductOrderTable.PaymentMethod.Show
              }}</label>
              <Dropdown
                v-model="data.PaymentMethodAddSearch"
                :options="data.selectPaymentMethodList"
                optionLabel="name"
                :filter="true"
                :placeholder="
                  i18n.$t('ProductOrder').ProductOrderTable.PaymentMethod.Show
                "
                :showClear="true"
              >
                <template #value="slotProps">
                  <div
                    class="country-item country-item-value"
                    v-if="slotProps.value"
                  >
                    <div>{{ slotProps.value.name }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="country-item">
                    <div>{{ slotProps.option.name }}</div>
                  </div>
                </template>
              </Dropdown>
            </div>
            <div class="p-field">
              <label for="name"
                >{{ i18n.$t("ProductOrder").ProductOrderTable.Remark.Show }}(
                <span style="color: red">{{
                  i18n.$t("Product").ProductTable.Remark
                }}</span
                >)
              </label>
              <Textarea
                id="description"
                v-model="data.add_product_order.remark"
                required="true"
                rows="3"
                cols="20"
              />
            </div>
          </div>
        </TabPanel>
      </TabView>
      <h4>{{ i18n.$t("Product").ProductTable.OrderContent }}</h4>
      <div
        :key="index"
        v-for="(content, index) in data.tmp_content"
        class="p-pb-1 p-mb-1"
        style="border-bottom: 1px solid gray"
      >
        <p>
          <span style="font-weight: bold" class="p-mr-1">{{
            content.product_name
          }}</span
          ><Chip
            v-for="item in content.add_sub_lists"
            :key="item.id"
            :class="
              content.total_count <= item.product_coupon.max_count &&
              content.total_count >= item.product_coupon.min_count &&
              item.status == 2
                ? 'p-mr-1'
                : 'p-d-none'
            "
          >
            {{ item.name }}
          </Chip>
          <br />
          <span style="font-size: 10px">${{ content.product_dollar }}</span>
        </p>
        <div style="display: flex">
          <div style="width: 50%">
            <InputNumber
              class="p-mb-2"
              style="height: 35px"
              inputStyle="width: 60px;"
              inputClass="p-text-center"
              inputId="horizontal"
              :min="0"
              :max="content.count"
              v-model="data.content_list[index].total_count"
              showButtons
              buttonLayout="horizontal"
              :step="1"
              decrementButtonClass="p-button-info"
              incrementButtonClass="p-button-info"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
            />
          </div>
          <div style="width: 50%; text-align: right; font-weight: bold">
            ${{ content.product_dollar * content.total_count
            }}<span
              :class="
                content.total_sub != 0 ? 'p-ml-1 p-text-danger' : 'p-d-none'
              "
              >({{ content.total_sub }})
            </span>
            <br /><i
              style="color: red"
              class="pi pi-trash p-ml-1"
              @click="RemoveContent(content.product_id)"
            ></i>
          </div>
        </div>
      </div>
      <div style="display: flex">
        <div style="width: 50%">
          {{ i18n.$t("Product").ProductTable.FinishOrder }}
        </div>
        <div style="width: 50%; text-align: right">
          ${{ data.content_total + data.sub_total }}
          <span :class="data.sub_total != 0 ? 'p-text-danger' : 'p-d-none'">
            ({{ data.content_total
            }}{{ data.sub_total > 0 ? "+" + data.sub_total : data.sub_total }})
          </span>
        </div>
      </div>
    </div>
    <div style="border-top: 1px solid gray" class="footer">
      <Button
        class="p-mb-2 p-mt-2 p-button-danger p-pt-2 p-pb-2 p-mr-2"
        style="width: 45%"
        :label="i18n.$t('Product').ProductTable.BackOrder"
        @click="data.save_add_cartDialog = false"
      />
      <Button
        class="p-mb-2 p-mt-2 p-button-info p-pt-2 p-pb-2"
        style="width: 45%"
        :label="i18n.$t('Product').ProductTable.FinishOrder"
        @click="FinishOrder"
      />
    </div>
  </Sidebar>
  <Sidebar v-model:visible="data.add_cartDialog" position="full">
    <div>
      <Image
        imageStyle="width: 100%;min-width:343px;"
        :src="data.product_content.image_url"
        :alt="data.product_content.name"
        preview
      />
    </div>
    <div class="footer">
      <div class="p-text-left p-mb-3 p-pl-4">
        <h2 style="color: black">
          {{ data.product_content.name }}
        </h2>
        <h3 style="color: black">
          {{ data.product_content.description }}
        </h3>
        <div style="color: black" v-if="data.product_content.is_top == 2">
          <i class="pi pi-heart-fill" style="font-size: 1rem"></i>
          {{ i18n.$t("Product").ProductTable.IsTop.Show }}
        </div>
        <div style="color: black">
          <i class="pi pi-tag product-category-icon"></i
          ><Chip
            v-for="item in data.product_content.product_types"
            :key="item.id"
            class="p-mr-1"
          >
            {{ item.name }}
          </Chip>
        </div>
        <div style="color: black" class="p-mt-1">
          <i
            v-if="checkStatus(data.product_content.add_sub_lists)"
            class="pi pi-money-bill product-category-icon"
          ></i
          ><Chip
            v-for="item in data.product_content.add_sub_lists"
            :key="item.id"
            :class="item.status == 2 ? 'p-mr-1' : 'p-d-none'"
          >
            {{ item.name }}
          </Chip>
        </div>
        <div class="p-mb-1" style="color: black">
          ${{ data.product_content.dollar }}
        </div>
        <span
          :class="
            data.product_content.count == 0
              ? 'product-badge status-outofstock'
              : 'product-badge status-instock'
          "
        >
          {{
            data.product_content.count == 0
              ? i18n.$t("Product").ProductTable.OutOfStock
              : i18n.$t("Product").ProductTable.InStock +
                ":" +
                data.product_content.count
          }}
        </span>
      </div>
      <InputNumber
        class="p-mb-2"
        style="width: 90%; height: 50px"
        inputClass="p-text-center"
        inputId="horizontal"
        v-model="data.content_number"
        showButtons
        buttonLayout="horizontal"
        :step="1"
        :min="0"
        :max="data.product_content.count"
        decrementButtonClass="p-button-info"
        incrementButtonClass="p-button-info"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
      />
      <Button
        class="p-mb-2 p-button-info p-pt-3 p-pb-3"
        style="width: 100%"
        :label="i18n.$t('Product').ProductTable.AddCar"
        @click="AddContent"
      />
    </div>
  </Sidebar>
  <Dialog
    v-model:visible="data.add_friend"
    :style="{ width: '450px' }"
    :header="i18n.$t('Product').AddFriend.Title"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span>{{ i18n.$t("Product").AddFriend.Content }}</span>
    </div>
    <template #footer>
      <Button
        :label="i18n.$t('Product').AddFriend.Confirm"
        icon="pi pi-check"
        class="p-button-text"
        @click="AddFriend"
      />
    </template>
  </Dialog>
</template>

<style>
.nav .p-tabview-nav-container {
  display: none;
}
</style>
<style lang="scss" scoped>
// .product-image {
//   width: 50px;
//   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
// }
// .product-image {
//   width: 50px;
//   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
//   margin-right: 2rem;
// }

.header {
  position: fixed;
  left: 0;
  bottom: 100;
  background: white;
  width: 100%;
  color: white;
  text-align: center;
  z-index: 9999999;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  background: white;
  width: 100%;
  color: white;
  text-align: center;
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
.product-toolbar {
  .pointer {
    cursor: pointer;
  }
  min-width: 375px;
  height: 85px;
  left: 0px;
  /* overflow: hidden; */
  background: white !important;
  position: fixed; /* Set the navbar to fixed position */
  width: 100%;
  top: 0;
  z-index: 99;
}
.product-type {
  width: 100%;
  z-index: 99;
  top: 85px;
  left: 0px;
  min-width: 375px;
  background: white !important;
  position: fixed; /* Set the navbar to fixed position */
}
.status-outofstock {
  background: #ffcdd2;
  color: #c63737;
}
.status-instock {
  background: #c8e6c9;
  color: #256029;
}
.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.card {
  background: #ffffff;
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}
.p-dropdown {
  width: 14rem;
  font-weight: normal;
}

.product-name {
  font-size: 1.5rem;
  font-weight: 700;
}

.product-description {
  margin: 0 0 1rem 0;
}

.product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.product-category {
  font-weight: 600;
  vertical-align: middle;
}

::v-deep(.product-list-item) {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;

  img {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
  }

  .product-list-detail {
    flex: 1 1 0;
  }

  .p-rating {
    margin: 0 0 0.5rem 0;
  }

  .product-price {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    align-self: flex-end;
  }

  .product-list-action {
    display: flex;
    flex-direction: column;
  }

  .p-button {
    margin-bottom: 0.5rem;
  }
}

::v-deep(.product-grid-item) {
  margin: 0.5rem;
  border: 1px solid var(--surface-border);

  .product-grid-item-top,
  .product-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  img {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
  }

  .product-grid-item-content {
    text-align: center;
  }

  .product-price {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

@media screen and (max-width: 576px) {
  .product-list-item {
    flex-direction: column;
    align-items: center;

    img {
      margin: 2rem 0;
    }

    .product-list-detail {
      text-align: center;
    }

    .product-price {
      align-self: center;
    }

    .product-list-action {
      display: flex;
      flex-direction: column;
    }

    .product-list-action {
      margin-top: 2rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}
</style>
