<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Dropdown from "primevue/dropdown";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Sub from "../api/salary/admin/sub";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import User from "../api/salary/auth/user";
import firebase from "firebase";
import router from "../router";

let userApi = new User();
let subAPI = new Sub();
const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  CodeEditSearch: "",
  CodeAddSearch: "",
  selectCodeList: [],
  ScopeOfRangeEditSearch: "",
  ScopeOfRangeAddSearch: "",
  ScopeOfRangeSearch: "",
  selectScopeOfRangeList: [
    {
      id: 1,
      name: i18n.$t("Sub").ScopeOfRange.LineUser,
    },
    {
      id: 2,
      name: i18n.$t("Sub").ScopeOfRange.Product,
    },
  ],
  add_sub: {
    name: "",
    code: "",
    dollar: "",
    percent: "",
    scope_of_range: "",
    description: "",
    product_coupon: "",
    line_user_coupon: "",
  },
  subLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  subs: { data: null },
  sub: null,
  page: 1,
  page_num: 10,
  selectedSubs: null,
  subFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    scope_of_range: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  CheckUser();
});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getSubList();
    getCodeSelectList();
  }
});

function getCodeSelectList() {
  subAPI.getCodeList().then((res) => {
    if (res.data.error_code == 0) {
      data.selectCodeList = res.data.data.datas;
      //   console.log(data.selectPaymentItemList);
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getSubList() {
  CheckUser();
  subAPI
    .getSubList(
      data.subFilters.id.value,
      data.subFilters.name.value,
      data.subFilters.scope_of_range.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.subs.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.subLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.subLoading = false;
      }
    });
}

function saveEditSub() {
  subAPI
    .editSub(
      data.sub.id,
      data.sub.name,
      data.sub.code,
      String(data.sub.dollar),
      String(data.sub.percent),
      data.sub.scope_of_range,
      data.sub.description
      //   data.sub.product_coupon,
      //   data.sub.line_user_coupon
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Sub").EditSuccess,
          life: 3000,
        });

        data.subLoading = true;
        getSubList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function saveAddSub() {
  console.log(data.add_sub);
  subAPI
    .addSub(
      data.add_sub.name,
      data.add_sub.code,
      String(data.add_sub.dollar),
      String(data.add_sub.percent),
      data.add_sub.scope_of_range,
      data.add_sub.description
      //   data.add_sub.product_coupon,
      //   data.add_sub.line_user_coupon
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Sub").AddSuccess,
          life: 3000,
        });

        data.subLoading = true;
        getSubList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function addSub() {
  CheckUser();
  data.addDialog = true;
  data.CodeAddSearch = "";
  data.ScopeOfRangeAddSearch = "";
  data.add_sub = {
    name: "",
    code: "",
    dollar: "",
    percent: "",
    scope_of_range: "",
    description: "",
    product_coupon: "",
    line_user_coupon: "",
  };
}
function editSub(sub) {
  CheckUser();
  data.sub = { ...sub };
  for (let i = 0; i < data.selectCodeList.length; i++) {
    if (data.selectCodeList[i].code == sub.code) {
      data.CodeEditSearch = data.selectCodeList[i];
      break;
    }
  }
  console.log(data.selectScopeOfRangeLis);
  for (let i = 0; i < data.selectScopeOfRangeList.length; i++) {
    if (data.selectScopeOfRangeList[i].id == sub.scope_of_range) {
      data.ScopeOfRangeEditSearch = data.selectScopeOfRangeList[i];
      break;
    }
  }
  data.editDialog = true;
}
function confirmDeleteSelected() {
  CheckUser();
  data.deleteSelectedDialog = true;
}
function confirmDelete(sub) {
  CheckUser();
  data.sub = sub;
  data.deleteDialog = true;
}
function deleteSub() {
  subAPI.deleteSub([data.sub.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Sub").DeleteSuccess,
        life: 3000,
      });

      data.subLoading = true;
      getSubList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedSub() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedSubs.length; i++) {
    selectDelete.push(data.selectedSubs[i].id);
  }
  subAPI.deleteSub(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Sub").DeleteSuccess,
        life: 3000,
      });

      data.subLoading = true;
      data.selectedSubs = [];
      getSubList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.subLoading = true;
    getSubList();
  }
);
watch(
  () => data.subFilters.name.value,
  () => {
    data.subLoading = true;
    getSubList();
  }
);
watch(
  () => data.subFilters.id.value,
  () => {
    data.subLoading = true;
    getSubList();
  }
);
watch(
  () => data.ScopeOfRangeSearch,
  () => {
    data.subLoading = true;
    data.subFilters.scope_of_range.value =
      data.ScopeOfRangeSearch == null ? "" : data.ScopeOfRangeSearch.id;
    getSubList();
  }
);
watch(
  () => data.ScopeOfRangeAddSearch,
  () => {
    data.add_sub.scope_of_range =
      data.ScopeOfRangeAddSearch == null ? "" : data.ScopeOfRangeAddSearch.id;
  }
);
watch(
  () => data.ScopeOfRangeEditSearch,
  () => {
    data.sub.scope_of_range =
      data.ScopeOfRangeEditSearch == null ? "" : data.ScopeOfRangeEditSearch.id;
  }
);
watch(
  () => data.CodeAddSearch,
  () => {
    data.add_sub.code =
      data.CodeAddSearch == null ? "" : data.CodeAddSearch.code;
  }
);
watch(
  () => data.CodeEditSearch,
  () => {
    data.sub.code = data.CodeEditSearch == null ? "" : data.CodeEditSearch.code;
  }
);
function editCouponStatus(coupon, status) {
  subAPI.editSubStatus(coupon.id, status, coupon.description).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Sub").EditSuccess,
        life: 3000,
      });

      data.subLoading = true;
      getSubList();
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.subs.data"
      :loading="data.subLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.subFilters"
      v-model:selection="data.selectedSubs"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Sub").SubTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.subFilters[i18n.$t('Sub').SubTable.ID.Field].value
                    "
                    :placeholder="i18n.$t('Sub').SubTable.ID.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.subFilters[i18n.$t('Sub').SubTable.Name.Field].value
                    "
                    :placeholder="i18n.$t('Sub').SubTable.Name.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.ScopeOfRangeSearch"
                    :options="data.selectScopeOfRangeList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Sub').SubTable.ScopeOfRange.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('Sub').SubTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addSub"
            />
            <Button
              :label="i18n.$t('Sub').SubTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="!data.selectedSubs || !data.selectedSubs.length"
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Sub').SubTable.ID.Field"
        :header="i18n.$t('Sub').SubTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Sub').SubTable.Name.Field"
        :header="i18n.$t('Sub').SubTable.Name.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Sub').SubTable.Dollar.Field"
        :header="i18n.$t('Sub').SubTable.Dollar.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Sub').SubTable.Percent.Field"
        :header="i18n.$t('Sub').SubTable.Percent.Show"
      ></Column>
      <Column
        class="p-col-2"
        :header="i18n.$t('Sub').SubTable.ScopeOfRange.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.scope_of_range == 1
              ? i18n.$t("Sub").ScopeOfRange.LineUser
              : slotProps.data.scope_of_range == 2
              ? i18n.$t("Sub").ScopeOfRange.Product
              : i18n.$t("Sub").ScopeOfRange.Unknow
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Sub').SubTable.Description.Field"
        :header="i18n.$t('Sub').SubTable.Description.Show"
      ></Column>
      <Column
        class="p-col-2"
        :header="i18n.$t('Product').ProductTable.Status.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.status == 1
              ? i18n.$t("Product").Disable
              : slotProps.data.status == 2
              ? i18n.$t("Product").Enabled
              : slotProps.data.status == 3
              ? i18n.$t("Product").Freeze
              : i18n.$t("Product").UnKnow
          }}
        </template>
      </Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editSub(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning p-mr-2"
            @click="confirmDelete(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.status == 2"
            v-tooltip="i18n.$t('Product').ToolTip.Disable"
            icon="pi pi-ban"
            class="p-button-rounded p-button-danger"
            @click="editCouponStatus(slotProps.data, 1)"
          />
          <Button
            v-else
            v-tooltip="i18n.$t('Product').ToolTip.Enabled"
            icon="pi pi-check"
            class="p-button-rounded p-button-success"
            @click="editCouponStatus(slotProps.data, 2)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Sub').AddSub.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{ i18n.$t("Sub").AddSub.Name }}</label>
        <InputText id="name" v-model.trim="data.add_sub.name" required="true" />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Sub").SubTable.Code.Show }}</label>
        <Dropdown
          v-model="data.CodeAddSearch"
          :options="data.selectCodeList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Sub').SubTable.Code.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Sub").SubTable.ScopeOfRange.Show
        }}</label>
        <Dropdown
          v-model="data.ScopeOfRangeAddSearch"
          :options="data.selectScopeOfRangeList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Sub').SubTable.ScopeOfRange.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Sub").SubTable.Dollar.Show }}</label>
        <InputNumber
          v-model="data.add_sub.dollar"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Sub").SubTable.Percent.Show }}</label>
        <InputNumber
          v-model="data.add_sub.percent"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Sub").AddSub.Description }}</label>
        <Textarea
          id="description"
          v-model="data.add_sub.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Sub').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('Sub').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddSub"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Sub').EditSub.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{ i18n.$t("Sub").EditSub.Name }}</label>
        <InputText id="name" v-model.trim="data.sub.name" required="true" />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Sub").SubTable.Code.Show }}</label>
        <Dropdown
          v-model="data.CodeEditSearch"
          :options="data.selectCodeList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Sub').SubTable.Code.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Sub").SubTable.ScopeOfRange.Show
        }}</label>
        <Dropdown
          v-model="data.ScopeOfRangeEditSearch"
          :options="data.selectScopeOfRangeList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Sub').SubTable.ScopeOfRange.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Sub").SubTable.Dollar.Show }}</label>
        <InputNumber
          v-model="data.sub.dollar"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Sub").SubTable.Percent.Show }}</label>
        <InputNumber
          v-model="data.sub.percent"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Sub").EditSub.Description }}</label>
        <Textarea
          id="description"
          v-model="data.sub.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Sub').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('Sub').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditSub"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Sub').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.sub"
          >{{ i18n.$t("Sub").Confirm.Content }} <b>{{ data.sub.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Sub').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('Sub').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSub"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Sub').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("Sub").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Sub').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('Sub').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedSub"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
