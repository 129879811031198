import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "buttersea",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class ImageRegister {
  getImageRegisterInfo(id) {
    return axios.get(
      `/api/admin/image-registry/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getImageRegisterList(id, name, page, page_num) {
    return axios.get(
      `/api/admin/image-registry/list`,
      {
        params: {
          id: id,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getImageRegisterSelectList(name) {
    return axios.get(
      `/api/admin/image-registry/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  batchImageRegister(datas) {
    return axios.post(
      `/api/admin/image-registry/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addImageRegister(name, image) {
    return axios.post(
      `/api/admin/image-registry/`,
      {
        name: name,
        image: image,
      },
      config
    );
  }
  editImageRegister(id, name, image) {
    return axios.put(
      `/api/admin/image-registry/`,
      {
        id: id,
        name: name,
        image: image,
      },
      config
    );
  }
  deleteImageRegister(id) {
    return axios.delete(
      `/api/admin/image-registry/`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
