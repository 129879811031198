<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";

// const i18n = useI18n();
// const toast = useToast();
const data = reactive({
  currentAccount: "",
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  ConnectTest().then((check) => {
    console.log(check);
    if (!check) {
      ConnectWallet();
    }
  });
});
async function ConnectWallet() {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      alert("Get MetaMask!");
      return;
    }
    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });
    console.log("Connected", accounts[0]);
    data.currentAccount = accounts[0];
  } catch (error) {
    console.log(error);
  }
}
async function ConnectTest() {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      alert("Make sure you have metamask!");
      return false;
    } else {
      console.log("We have the ethereum object", ethereum);
    }
    const accounts = await ethereum.request({ method: "eth_accounts" });
    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account:", account);
      this.currentAccount = account;
      return true;
    } else {
      console.log("No authorized account found");
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}
</script>

<template>
  <div class="p-mt-5">
    <div style="margin-top: 150px"></div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
