import { createStore } from "vuex";
// import { app } from "../main"; //匯入我們的Vue Instance

export default createStore({
  state: {
    lang: "cn",
    user: null,
    employer: null,
    shop: [],
    shop_already: [],
  },
  mutations: {
    set_lang(state, value) {
      state.lang = value;
    },
    set_user(state, user) {
      state.user = user;
    },
    set_employer(state, employer) {
      state.employer = employer;
    },
    set_shop(state, shop) {
      state.shop = shop;
    },
    set_shop_already(state, shop_already) {
      state.shop_already = shop_already;
    },
  },
  actions: {},
  modules: {},
});
