<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  ref,
} from "vue";
import Chip from "primevue/chip";
import MultiSelect from "primevue/multiselect";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import StoreInformation from "../api/salary/admin/store_information";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import { dateFormate } from "../tool/formate";
import User from "../api/salary/auth/user";
import firebase from "firebase";
import router from "../router";

let userApi = new User();
let store_informationAPI = new StoreInformation();
const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  selectedEditOpenWeekly: [],
  selectedAddOpenWeekly: [],
  selectOpenWeeklyList: [
    {
      id: 1,
      name: i18n.$t("StoreInformation").OpenWeekly.Monday,
    },
    {
      id: 2,
      name: i18n.$t("StoreInformation").OpenWeekly.Tuesday,
    },
    {
      id: 3,
      name: i18n.$t("StoreInformation").OpenWeekly.Wednesday,
    },
    {
      id: 4,
      name: i18n.$t("StoreInformation").OpenWeekly.Thursday,
    },
    {
      id: 5,
      name: i18n.$t("StoreInformation").OpenWeekly.Friday,
    },
    {
      id: 6,
      name: i18n.$t("StoreInformation").OpenWeekly.Saturday,
    },
    {
      id: 7,
      name: i18n.$t("StoreInformation").OpenWeekly.Sunday,
    },
  ],
  minDate: new Date(),
  add_store_information: {
    id: "",
    name: "",
    atm_account_number: "",
    atm_bank_number: "",
    line_start_time: "",
    line_end_time: "",
    entity_open_time_description: "",
    physical_address: "",
    open_weekly: [],
  },
  store_informationLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  store_informations: { data: null },
  store_information: null,
  page: 1,
  page_num: 10,
  selectedStoreInformations: null,
  store_informationFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  CheckUser();
});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getStoreInformationList();
  }
});

function getStoreInformationList() {
  CheckUser();
  store_informationAPI
    .getStoreInformationList(
      data.store_informationFilters.id.value,
      data.store_informationFilters.name.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.store_informations.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.store_informationLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.store_informationLoading = false;
      }
    });
}

function saveEditStoreInformation() {
  store_informationAPI
    .editStoreInformation(
      data.store_information.id,
      data.store_information.name,
      data.store_information.atm_account_number,
      data.store_information.atm_bank_number,
      data.store_information.line_start_time != "" &&
        data.store_information.line_start_time != null
        ? data.store_information.line_start_time.toISOString()
        : "",
      data.store_information.line_end_time != "" &&
        data.store_information.line_end_time != null
        ? data.store_information.line_end_time.toISOString()
        : "",
      data.store_information.entity_open_time_description,
      data.store_information.physical_address,
      data.store_information.open_weekly
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("StoreInformation").EditSuccess,
          life: 3000,
        });

        data.store_informationLoading = true;
        getStoreInformationList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function saveAddStoreInformation() {
  store_informationAPI
    .addStoreInformation(
      data.add_store_information.name,
      data.add_store_information.atm_account_number,
      data.add_store_information.atm_bank_number,
      data.add_store_information.line_start_time != "" &&
        data.add_store_information.line_start_time != null
        ? data.add_store_information.line_start_time.toISOString()
        : "",
      data.add_store_information.line_end_time != "" &&
        data.add_store_information.line_end_time != null
        ? data.add_store_information.line_end_time.toISOString()
        : "",
      data.add_store_information.entity_open_time_description,
      data.add_store_information.physical_address,
      data.add_store_information.open_weekly
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("StoreInformation").AddSuccess,
          life: 3000,
        });

        data.store_informationLoading = true;
        getStoreInformationList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function addStoreInformation() {
  CheckUser();
  data.addDialog = true;
  data.add_store_information = {
    id: "",
    name: "",
    atm_account_number: "",
    atm_bank_number: "",
    line_start_time: "",
    line_end_time: "",
    entity_open_time_description: "",
    physical_address: "",
    open_weekly: [],
  };
  data.selectedAddOpenWeekly = "";
}
async function editStoreInformation(store_information) {
  CheckUser();

  data.store_information = { ...store_information };
  data.store_information.line_start_time = new Date(
    store_information.line_start_time
  );
  data.store_information.line_end_time = new Date(
    store_information.line_end_time
  );
  let tmp = [];
  if (store_information.open_weekly != null) {
    for (let i = 0; i < store_information.open_weekly.length; i++) {
      store_information.open_weekly[i];
      if (store_information.open_weekly[i] != 0) {
        await tmp.push(
          data.selectOpenWeeklyList[store_information.open_weekly[i] - 1]
        );
      }
    }
  }
  data.selectedEditOpenWeekly = tmp;
  data.editDialog = true;
}
function confirmDeleteSelected() {
  CheckUser();
  data.deleteSelectedDialog = true;
}
function confirmDelete(store_information) {
  CheckUser();
  data.store_information = store_information;
  data.deleteDialog = true;
}
function deleteStoreInformation() {
  store_informationAPI
    .deleteStoreInformation([data.store_information.id])
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("StoreInformation").DeleteSuccess,
          life: 3000,
        });

        data.store_informationLoading = true;
        getStoreInformationList();
        data.deleteDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function deleteSelectedStoreInformation() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedStoreInformations.length; i++) {
    selectDelete.push(data.selectedStoreInformations[i].id);
  }
  store_informationAPI.deleteStoreInformation(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("StoreInformation").DeleteSuccess,
        life: 3000,
      });

      data.store_informationLoading = true;
      data.selectedStoreInformations = [];
      getStoreInformationList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.store_informationLoading = true;
    getStoreInformationList();
  }
);
watch(
  () => data.store_informationFilters.name.value,
  () => {
    data.store_informationLoading = true;
    getStoreInformationList();
  }
);
watch(
  () => data.store_informationFilters.id.value,
  () => {
    data.store_informationLoading = true;
    getStoreInformationList();
  }
);
watch(
  () => data.selectedAddOpenWeekly,
  async () => {
    let tmpIDs = [];
    for (let i = 0; i < data.selectedAddOpenWeekly.length; i++) {
      // if (i == 0) {
      //   tmpIDs = tmpIDs + data.selectedAddOpenWeekly[i].id;
      // } else {
      //   tmpIDs = tmpIDs + "," + data.selectedAddOpenWeekly[i].id;
      // }
      tmpIDs.push(data.selectedAddOpenWeekly[i].id);
    }

    data.add_store_information.open_weekly = tmpIDs.sort();
  }
);
watch(
  () => data.selectedEditOpenWeekly,
  async () => {
    let tmpIDs = [];
    for (let i = 0; i < data.selectedEditOpenWeekly.length; i++) {
      // if (i == 0) {
      //   tmpIDs = tmpIDs + data.selectedAddOpenWeekly[i].id;
      // } else {
      //   tmpIDs = tmpIDs + "," + data.selectedAddOpenWeekly[i].id;
      // }
      tmpIDs.push(data.selectedEditOpenWeekly[i].id);
    }
    data.store_information.open_weekly = tmpIDs.sort();
  }
);
const start = ref();
const end = ref();
const add_start = ref();
const add_end = ref();
function closeCalendar(item) {
  switch (item) {
    case "start":
      start.value.overlayVisible = false;
      break;
    case "end":
      end.value.overlayVisible = false;
      break;
    case "add_start":
      add_start.value.overlayVisible = false;
      break;
    case "add_end":
      add_end.value.overlayVisible = false;
      break;
  }
}
function todayCalendar(item) {
  let date = new Date();
  let dateMeta = {
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
    otherMonth:
      date.getMonth() !== this.currentMonth ||
      date.getFullYear() !== this.currentYear,
    today: true,
    selectable: true,
  };
  switch (item) {
    case "start":
      start.value.onDateSelect(null, dateMeta);
      start.value.$emit("today-click", date);
      break;
    case "end":
      end.value.onDateSelect(null, dateMeta);
      end.value.$emit("today-click", date);
      break;
    case "add_start":
      add_start.value.onDateSelect(null, dateMeta);
      add_start.value.$emit("today-click", date);
      break;
    case "add_end":
      add_end.value.onDateSelect(null, dateMeta);
      add_end.value.$emit("today-click", date);
      break;
  }
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.store_informations.data"
      :loading="data.store_informationLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.store_informationFilters"
      v-model:selection="data.selectedStoreInformations"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{
                  i18n.$t("StoreInformation").StoreInformationTable.Search
                }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.store_informationFilters[
                        i18n.$t('StoreInformation').StoreInformationTable.ID
                          .Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('StoreInformation').StoreInformationTable.ID.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.store_informationFilters[
                        i18n.$t('StoreInformation').StoreInformationTable.Name
                          .Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('StoreInformation').StoreInformationTable.Name
                        .Show
                    "
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('StoreInformation').StoreInformationTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addStoreInformation"
              :disabled="data.totalRecords != 0"
            />
            <Button
              :label="i18n.$t('StoreInformation').StoreInformationTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedStoreInformations ||
                !data.selectedStoreInformations.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('StoreInformation').StoreInformationTable.ID.Field"
        :header="i18n.$t('StoreInformation').StoreInformationTable.ID.Show"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('StoreInformation').StoreInformationTable.Name.Field"
        :header="i18n.$t('StoreInformation').StoreInformationTable.Name.Show"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('StoreInformation').StoreInformationTable.AtmAccountNumber
            .Field
        "
        :header="
          i18n.$t('StoreInformation').StoreInformationTable.AtmAccountNumber
            .Show
        "
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('StoreInformation').StoreInformationTable.AtmBankNumber.Field
        "
        :header="
          i18n.$t('StoreInformation').StoreInformationTable.AtmBankNumber.Show
        "
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      ></Column>
      <Column
        class="p-col-2"
        :header="
          i18n.$t('StoreInformation').StoreInformationTable.LineStartTime.Show
        "
        bodyStyle="white-space: nowrap; width: 300px; overflow: scroll"
      >
        <template #body="slotProps">
          {{
            slotProps.data.line_start_time == ""
              ? i18n.$t("ProductOrder").UnKnow
              : dateFormate(slotProps.data.line_start_time, "YYYY-MM-DD hh:mm")
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :header="
          i18n.$t('StoreInformation').StoreInformationTable.LineEndTime.Show
        "
        bodyStyle="white-space: nowrap; width: 300px; overflow: scroll"
      >
        <template #body="slotProps">
          {{
            slotProps.data.line_end_time == ""
              ? i18n.$t("ProductOrder").UnKnow
              : dateFormate(slotProps.data.line_end_time, "YYYY-MM-DD hh:mm")
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('StoreInformation').StoreInformationTable.PhysicalAddress
            .Field
        "
        :header="
          i18n.$t('StoreInformation').StoreInformationTable.PhysicalAddress.Show
        "
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('StoreInformation').StoreInformationTable
            .EntityOpenTimeDescription.Field
        "
        :header="
          i18n.$t('StoreInformation').StoreInformationTable
            .EntityOpenTimeDescription.Show
        "
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('StoreInformation').StoreInformationTable.OpenWeekly.Field
        "
        :header="
          i18n.$t('StoreInformation').StoreInformationTable.OpenWeekly.Show
        "
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      >
        <template #body="slotProps">
          <Chip
            v-for="item in slotProps.data.open_weekly"
            :key="item"
            :class="item == 0 ? 'p-d-none' : 'p-mr-1'"
          >
            {{
              data.selectOpenWeeklyList[item - 1] != null &&
              data.selectOpenWeeklyList[item - 1] != undefined
                ? data.selectOpenWeeklyList[item - 1].name
                : ""
            }}
          </Chip>
        </template>
      </Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editStoreInformation(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('StoreInformation').AddStoreInformation.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("StoreInformation").StoreInformationTable.Name.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_store_information.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("StoreInformation").StoreInformationTable.AtmAccountNumber
            .Show
        }}</label>
        <InputText
          id="name"
          type="number"
          v-model.trim="data.add_store_information.atm_account_number"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("StoreInformation").StoreInformationTable.AtmBankNumber.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_store_information.atm_bank_number"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("StoreInformation").StoreInformationTable.LineStartTime.Show
        }}</label>
        <Calendar
          id="range"
          v-model="data.add_store_information.line_start_time"
          :minDate="data.minDate"
          :manualInput="false"
          :showIcon="true"
          :showTime="true"
          :placeholder="
            i18n.$t('StoreInformation').StoreInformationTable.LineStartTime.Show
          "
          ref="add_start"
        >
          <template #footer>
            <div class="p-datepicker-buttonbar">
              <Button
                @click="todayCalendar('add_start')"
                class="p-button p-component p-button-text"
                >Today</Button
              >
              <Button
                @click="closeCalendar('add_start')"
                class="p-button p-component p-button-text"
                >Submit</Button
              >
            </div>
          </template>
        </Calendar>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("StoreInformation").StoreInformationTable.LineEndTime.Show
        }}</label>
        <Calendar
          id="range"
          v-model="data.add_store_information.line_end_time"
          :minDate="data.minDate"
          :manualInput="false"
          :showIcon="true"
          :showTime="true"
          :placeholder="
            i18n.$t('StoreInformation').StoreInformationTable.LineEndTime.Show
          "
          ref="end_start"
        >
          <template #footer>
            <div class="p-datepicker-buttonbar">
              <Button
                @click="todayCalendar('end_start')"
                class="p-button p-component p-button-text"
                >Today</Button
              >
              <Button
                @click="closeCalendar('end_start')"
                class="p-button p-component p-button-text"
                >Submit</Button
              >
            </div>
          </template>
        </Calendar>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("StoreInformation").StoreInformationTable.PhysicalAddress.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.add_store_information.physical_address"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("StoreInformation").StoreInformationTable
            .EntityOpenTimeDescription.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.add_store_information.entity_open_time_description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("StoreInformation").StoreInformationTable.OpenWeekly.Show
        }}</label>
        <MultiSelect
          v-model="data.selectedAddOpenWeekly"
          :options="data.selectOpenWeeklyList"
          optionLabel="name"
          :placeholder="
            i18n.$t('StoreInformation').StoreInformationTable.OpenWeekly.Show
          "
          class="multiselect-custom"
        >
          <template #value="slotProps">
            <div
              class="country-item country-item-value"
              v-for="option of slotProps.value"
              :key="option.id"
            >
              <div>{{ option.name }}</div>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{
                i18n.$t("StoreInformation").StoreInformationTable.OpenWeekly
                  .Show
              }}
            </template>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </MultiSelect>
        <!-- <InputText
          v-model.trim="data.add_store_information.open_weekly"
          required="true"
        /> -->
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('StoreInformation').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('StoreInformation').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddStoreInformation"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('StoreInformation').EditStoreInformation.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("StoreInformation").StoreInformationTable.Name.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.store_information.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("StoreInformation").StoreInformationTable.AtmAccountNumber
            .Show
        }}</label>
        <InputText
          id="name"
          type="number"
          v-model.trim="data.store_information.atm_account_number"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("StoreInformation").StoreInformationTable.AtmBankNumber.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.store_information.atm_bank_number"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("StoreInformation").StoreInformationTable.LineStartTime.Show
        }}</label>
        <Calendar
          id="range"
          v-model="data.store_information.line_start_time"
          :manualInput="false"
          :minDate="data.minDate"
          :showIcon="true"
          :showTime="true"
          :placeholder="
            i18n.$t('StoreInformation').StoreInformationTable.LineStartTime.Show
          "
          ref="start"
        >
          <template #footer>
            <div class="p-datepicker-buttonbar">
              <Button
                @click="todayCalendar('start')"
                class="p-button p-component p-button-text"
                >Today</Button
              >
              <Button
                @click="closeCalendar('start')"
                class="p-button p-component p-button-text"
                >Submit</Button
              >
            </div>
          </template>
        </Calendar>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("StoreInformation").StoreInformationTable.LineEndTime.Show
        }}</label>
        <Calendar
          id="range"
          v-model="data.store_information.line_end_time"
          :minDate="data.minDate"
          :manualInput="false"
          :showIcon="true"
          :showTime="true"
          :placeholder="
            i18n.$t('StoreInformation').StoreInformationTable.LineEndTime.Show
          "
          ref="end"
        >
          <template #footer>
            <div class="p-datepicker-buttonbar">
              <Button
                @click="todayCalendar('end')"
                class="p-button p-component p-button-text"
                >Today</Button
              >
              <Button
                @click="closeCalendar('end')"
                class="p-button p-component p-button-text"
                >Submit</Button
              >
            </div>
          </template>
        </Calendar>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("StoreInformation").StoreInformationTable.PhysicalAddress.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.store_information.physical_address"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("StoreInformation").StoreInformationTable
            .EntityOpenTimeDescription.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.store_information.entity_open_time_description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("StoreInformation").StoreInformationTable.OpenWeekly.Show
        }}</label>
        <MultiSelect
          v-model="data.selectedEditOpenWeekly"
          :options="data.selectOpenWeeklyList"
          optionLabel="name"
          :placeholder="
            i18n.$t('StoreInformation').StoreInformationTable.OpenWeekly.Show
          "
          class="multiselect-custom"
        >
          <template #value="slotProps">
            <div
              class="country-item country-item-value"
              v-for="option of slotProps.value"
              :key="option.id"
            >
              <div>{{ option.name }}</div>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{
                i18n.$t("StoreInformation").StoreInformationTable.OpenWeekly
                  .Show
              }}
            </template>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </MultiSelect>
        <!-- <InputText
          v-model.trim="data.store_information.open_weekly"
          required="true"
        /> -->
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('StoreInformation').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('StoreInformation').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditStoreInformation"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('StoreInformation').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.store_information"
          >{{ i18n.$t("StoreInformation").Confirm.Content }}
          <b>{{ data.store_information.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('StoreInformation').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('StoreInformation').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteStoreInformation"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('StoreInformation').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("StoreInformation").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('StoreInformation').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('StoreInformation').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedStoreInformation"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
