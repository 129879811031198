import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "warehouse",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class User {
  checkUser() {
    return axios.get(
      `/api/auth/user`,
      {
        params: {},
      },
      config
    );
  }
  loginUser(username, password, auth_method) {
    return axios.post(
      `/api/auth/login`,
      {
        username: username,
        password: password,
        auth_method: auth_method,
      },
      config
    );
  }
  logoutUser() {
    return axios.post(`/api/auth/logout`, {}, config);
  }
}
