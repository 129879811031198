<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";

import LineUser from "../api/salary/admin/line_user";
import Textarea from "primevue/textarea";
import Button from "primevue/button";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { useI18n } from "../i18nPlugin";
const i18n = useI18n();
const toast = useToast();

let line_userAPI = new LineUser();
const data = reactive({
  msg: "",
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {});
function SendMsg() {
  line_userAPI.sendMsg(data.msg).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Msg").Success,
        life: 3000,
      });
      data.msg = "";
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <h1 style="margin-top: 100px">LINE推播內容</h1>
    <div style="margin-top: 10px">
      <Textarea
        id="description"
        v-model="data.msg"
        required="true"
        style="width: 100%; height: 60vh"
        rows="3"
        cols="20"
      />
      <Button
        icon="pi pi-send"
        label="Send"
        class="p-button p-button-info p-mt-2"
        @click="SendMsg"
      />
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
