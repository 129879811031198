<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
// import liff from "@line/liff";
// const i18n = useI18n();
// const toast = useToast();
import Button from "primevue/button";
import router from "../router";
const data = reactive({
  domain_name: "https://buttersea.leyutechs.com/",
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  try {
    await liff.init({ liffId: "1657604931-l3rXdPBg" }); // Use own liffId
    if (!liff.isLoggedIn()) liff.login({ redirectUri: window.location.href });
  } catch (err) {
    console.log(`liff.state init error ${err}`);
  }
});
async function sendTargetPicker() {
  if (!liff.isLoggedIn()) {
    liff.login({ redirectUri: window.location.href });
  }
  if (liff.isApiAvailable("shareTargetPicker")) {
    let item = {
      image_url: decodeURI(router.currentRoute._value.query.image_url),
      name: decodeURI(router.currentRoute._value.query.name),
      description: decodeURI(router.currentRoute._value.query.description),
    };
    try {
      let uri =
        "https://liff.line.me/1657604931-l3rXdPBg?image_url=" +
        item.image_url +
        "&name=" +
        item.name;
      if (item.description != "") {
        uri = uri + "&description=" + item.description;
      }
      const picker = await liff.shareTargetPicker([
        {
          type: "flex",
          altText: "好東西與您分享",
          contents: {
            type: "bubble",
            body: {
              type: "box",
              layout: "vertical",
              contents: [
                {
                  type: "image",
                  url: data.domain_name + item.image_url,
                  size: "full",
                  aspectMode: "fit",
                  aspectRatio: "1.75:3",
                  gravity: "top",
                  offsetBottom: "53px",
                },
                {
                  type: "box",
                  layout: "vertical",
                  contents: [
                    {
                      type: "box",
                      layout: "vertical",
                      contents: [
                        {
                          type: "text",
                          text: item.name,
                          size: "xl",
                          color: "#ffffff",
                          weight: "bold",
                        },
                      ],
                    },
                    // {
                    //   type: "box",
                    //   layout: "baseline",
                    //   contents: [
                    //     {
                    //       type: "text",
                    //       text: item.description,
                    //       color: "#ebebeb",
                    //       size: "sm",
                    //       flex: 0,
                    //       wrap: true,
                    //     },
                    //   ],
                    //   spacing: "lg",
                    // },
                    {
                      type: "box",
                      layout: "vertical",
                      contents: [
                        {
                          type: "filler",
                        },
                        {
                          type: "box",
                          layout: "baseline",
                          contents: [
                            {
                              type: "filler",
                            },
                            {
                              type: "text",
                              text: "快速購買",
                              color: "#ffffff",
                              flex: 0,
                              offsetTop: "-2px",
                            },
                            {
                              type: "filler",
                            },
                          ],
                          spacing: "sm",
                          action: {
                            type: "uri",
                            label: "快速購買",
                            uri: "https://liff.line.me/1657604931-yGp8emB6",
                          },
                        },
                        {
                          type: "filler",
                        },
                      ],
                      borderWidth: "1px",
                      cornerRadius: "4px",
                      spacing: "sm",
                      borderColor: "#ffffff",
                      margin: "xxl",
                      height: "40px",
                    },
                  ],
                  position: "absolute",
                  offsetBottom: "0px",
                  offsetStart: "0px",
                  offsetEnd: "0px",
                  backgroundColor: "#03303Acc",
                  paddingAll: "20px",
                  paddingTop: "18px",
                },
                {
                  type: "box",
                  layout: "vertical",
                  contents: [
                    {
                      type: "text",
                      text: "每日推薦",
                      color: "#ffffff",
                      align: "center",
                      size: "xs",
                      offsetTop: "3px",
                      wrap: true,
                    },
                  ],
                  position: "absolute",
                  cornerRadius: "20px",
                  offsetTop: "18px",
                  backgroundColor: "#ff334b",
                  offsetStart: "18px",
                  height: "25px",
                  width: "100px",
                },
                {
                  type: "box",
                  layout: "vertical",
                  contents: [
                    {
                      type: "text",
                      text: "好友分享",
                      align: "center",
                      size: "xs",
                      offsetTop: "3px",
                      wrap: true,
                    },
                  ],
                  position: "absolute",
                  cornerRadius: "20px",
                  offsetTop: "18px",
                  backgroundColor: "#a6ed8e",
                  height: "25px",
                  width: "100px",
                  offsetEnd: "18px",
                  action: {
                    type: "uri",
                    label: "action",
                    uri: uri,
                  },
                },
              ],
              paddingAll: "0px",
            },
          },
        },
      ]);
      if (picker) {
        // succeeded in sending a message through TargetPicker
        console.log(`[${picker.status}] Message sent!`);
      } else {
        const [majorVer, minorVer] = (liff.getLineVersion() || "").split(".");
        if (parseInt(majorVer) == 10 && parseInt(minorVer) < 11) {
          console.log(
            "TargetPicker was opened at least. Whether succeeded to send message is unclear"
          );
        } else console.log("TargetPicker was closed!");
      }
    } catch (error) {
      // something went wrong before sending a message
      console.log(error);
      console.log("Flex Message got some error");
      liff.closeWindow();
    }
  } else console.log("Please login...");
}
</script>

<template>
  <div
    style="
      width: 100%;
      height: 100vh;
      display: flex; /* ★ */
      height: 100%;
      align-items: center;
      justify-content: center;
    "
    class="p-text-center"
  >
    <Button
      icon="pi pi-reply"
      @click="sendTargetPicker"
      label="Share"
      class="p-button-lg p-button-success"
    >
    </Button>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
