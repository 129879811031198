import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "buttersea",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class ProductOrderContent {
  getProductOrderContentList(
    line_user_user_id,
    product_order_id,
    page,
    page_num
  ) {
    return axios.get(
      `/line/user/product-order-content/list`,
      {
        params: {
          line_user_user_id: line_user_user_id,
          product_order_id: product_order_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
}
