<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Card from "primevue/card";
import Skeleton from "primevue/skeleton";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import FileUpload from "primevue/fileUpload";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Dropdown from "primevue/dropdown";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Product from "../api/salary/admin/product";
import NormalProduct from "../api/salary/line/product";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import User from "../api/salary/auth/user";
import firebase from "firebase";
import router from "../router";
import { getBase64 } from "../tool/image";

let userApi = new User();
let productAPI = new Product();
let normal_productAPI = new NormalProduct();

const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  selectStatusList: [
    {
      id: 1,
      name: i18n.$t("Product").Disable,
    },
    {
      id: 2,
      name: i18n.$t("Product").Enabled,
    },
    {
      id: 3,
      name: i18n.$t("Product").Freeze,
    },
  ],
  add_product: {
    id: "",
    name: "",
    code: "",
    dollar: 0,
    count: 0,
    image: "",
  },
  productLoading: false,
  totalRecords: 0,
  products: { data: null },
  product: null,
  page: 1,
  page_num: 10,
  selectedProducts: null,
  productFilters: {
    code: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
    is_top: { value: "", matchMode: "contains" },
  },
  selectIsTopList: [
    {
      id: 1,
      name: i18n.$t("Product").NoIsTopShow,
    },
    {
      id: 2,
      name: i18n.$t("Product").IsTopShow,
    },
  ],
});

const beforemounted = onBeforeMount(async () => {
  if (router.currentRoute.value.fullPath != "/product-show") {
    CheckUser();
  }
});

const mounted = onMounted(async () => {
  data.productLoading = true;
  getProductList();
  // if (store.state.user != null) {
  //   getProductList();
  // }
});

function getProductList() {
  normal_productAPI
    .getProductList(
      data.productFilters.code.value,
      data.productFilters.name.value,
      2,
      data.productFilters.is_top.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.products.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.productLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.productLoading = false;
      }
    });
}
function handleScroll(e) {
  if (
    e.target.clientHeight + Math.ceil(e.target.scrollTop) >=
      e.target.scrollHeight &&
    data.page < Math.ceil(data.totalRecords / data.page_num)
  ) {
    data.productLoading = true;
    data.page = data.page + 1;
    getProductList();
  }
}
function Choose(product) {
  if (
    store.state.shop.filter((e) => e.id === product.id).length == 0 &&
    store.state.shop_already.filter((e) => e.product_id === product.id)
      .length == 0
  ) {
    product.count = 0;
    store.state.shop.push(product);
  }
}
function Remove(product) {
  for (var i = 0; i < store.state.shop.length; i++) {
    var obj = store.state.shop[i];

    if (obj.id == product.id) {
      store.state.shop.splice(i, 1);
    }
  }
}

// function onPage(event) {
//   console.log(event);
//   data.page = event.page + 1;
// }
watch(
  () => data.page,
  () => {
    data.productLoading = true;
    getProductList();
  }
);
watch(
  () => data.productFilters.name.value,
  () => {
    data.productLoading = true;
    getProductList();
  }
);
watch(
  () => data.productFilters.code.value,
  () => {
    data.productLoading = true;
    getProductList();
  }
);
watch(
  () => data.StatusSearch,
  () => {
    data.productLoading = true;
    if (data.StatusSearch != null) {
      data.productFilters.status.value =
        data.StatusSearch == null ? "" : data.StatusSearch.id;
    } else {
      data.productFilters.status.value = "";
    }
    getProductList();
  }
);
watch(
  () => data.IsTopSearch,
  () => {
    data.productFilters.is_top.value =
      data.IsTopSearch == null ? "" : data.IsTopSearch.id;
    getProductList();
  }
);
</script>

<template>
  <Toast />
  <div>
    <Accordion class="accordion-custom p-col-12" :activeIndex="0">
      <AccordionTab>
        <template #header>
          <i class="pi pi-search p-mr-1"></i>
          <span>{{ i18n.$t("Product").ProductTable.Search }}</span>
        </template>
        <p style="text-align: left">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <InputText
              style="width: 150px"
              v-model="
                data.productFilters[i18n.$t('Product').ProductTable.Code.Field]
                  .value
              "
              :placeholder="i18n.$t('Product').ProductTable.Code.Show"
            />
          </span>
          <br />
          <span class="p-input-icon-left p-mt-1">
            <i class="pi pi-search" />
            <InputText
              style="width: 150px"
              v-model="
                data.productFilters[i18n.$t('Product').ProductTable.Name.Field]
                  .value
              "
              :placeholder="i18n.$t('Product').ProductTable.Name.Show"
            />
          </span>
          <br />
          <span class="p-input-icon-left p-mt-1">
            <i class="pi pi-search" />
            <Dropdown
              v-model="data.IsTopSearch"
              :options="data.selectIsTopList"
              optionLabel="name"
              :filter="true"
              :placeholder="i18n.$t('Product').ProductTable.IsTop.Show"
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </span>
        </p>
      </AccordionTab>
    </Accordion>
    <div
      class="p-grid"
      style="height: calc(100vh - 240px); overflow: auto"
      @scroll="handleScroll"
    >
      <div
        :key="index"
        v-for="(product, index) in data.products.data"
        class="p-col-12"
      >
        <!-- <div
          style="height: 25px; width: 100%; background-color: CornflowerBlue"
        >
          &nbsp;
        </div> -->
        <Card
          v-if="product.count != 0"
          style="width: 100%; border-radius: 30px; border: groove 0.5em gray"
          class="p-text-left"
        >
          <template #content>
            <div class="p-grid">
              <div class="p-col-6 p-grid">
                <div class="p-col-12">
                  <span style="display: inline-block; width: 75px"
                    >{{ i18n.$t("Product").ProductTable.Code.Show }}:</span
                  ><br />
                  <div
                    style="white-space: nowrap; width: 150px; overflow: scroll"
                  >
                    {{ product.code }}
                  </div>
                </div>
                <div class="p-col-12">
                  <span style="display: inline-block; width: 75px"
                    >{{ i18n.$t("Product").ProductTable.Name.Show }}:</span
                  ><br />
                  <div style="white-space: nowrap; width: 100%; overflow: auto">
                    {{ product.name }}
                  </div>
                </div>
                <div class="p-col-12">
                  <span style="display: inline-block; width: 75px"
                    >{{ i18n.$t("Product").ProductTable.Dollar.Show }}:</span
                  ><br />
                  <div style="white-space: nowrap; width: 100%; overflow: auto">
                    {{ product.dollar }}
                  </div>
                </div>
                <div class="p-col-12">
                  <span style="display: inline-block; width: 75px"
                    >{{ i18n.$t("Product").ProductTable.Count.Show }}:</span
                  ><br />
                  <div style="white-space: nowrap; width: 100%; overflow: auto">
                    {{ product.count }}
                  </div>
                </div>
              </div>
              <div class="p-col-6">
                <img
                  class="p-ml-4"
                  style="width: 95%"
                  :src="product.image_url"
                />
              </div>
              <div
                v-if="
                  router.currentRoute.value.fullPath != '/product-show' &&
                  store.state.shop.filter((e) => e.id === product.id).length ==
                    0 &&
                  store.state.shop_already.filter(
                    (e) => e.product_id === product.id
                  ).length == 0
                "
                class="p-col-12"
              >
                <Button
                  class="p-button-info"
                  icon="pi pi-check"
                  :label="i18n.$t('Product').Choose"
                  @click="Choose(product)"
                />
              </div>
              <div
                v-if="
                  router.currentRoute.value.fullPath != '/product-show' &&
                  store.state.shop.filter((e) => e.id === product.id).length !=
                    0
                "
                class="p-col-12"
              >
                <Button
                  class="p-button-danger"
                  icon="pi pi-times"
                  :label="i18n.$t('Product').Remove"
                  @click="Remove(product)"
                />
              </div>
            </div>
          </template>
          <!-- <template #footer>
            <div>
              <Button
                class="p-button-info"
                icon="pi pi-check"
                :label="i18n.$t('Product').Choose"
                @click="Choose(product)"
              />
            </div>
          </template> -->
        </Card>
      </div>
      <div v-if="data.productLoading" class="p-grid p-col-12">
        <div
          :key="index"
          v-for="(item, index) in [1, 2, 3, 4, 5, 6, 7, 8]"
          class="p-col-12"
        >
          <div class="custom-skeleton p-4 p-pt-4">
            <div class="p-pl-2 p-pr-2">
              <Skeleton width="100%" class="p-mb-2"></Skeleton>
              <Skeleton width="100%" class="p-mb-2"></Skeleton>
              <Skeleton width="100%" class="p-mb-2"></Skeleton>
              <Skeleton width="100%" class="p-mb-2"></Skeleton>
            </div>
            <div class="p-grid p-mt-3 p-pl-3 p-mb-4">
              <Skeleton width="5rem" height="2.5rem"></Skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
