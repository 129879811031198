import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "buttersea",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Product {
  getProductList(code, name, status, is_top, product_type_ids, page, page_num) {
    return axios.get(
      `/line/product/list`,
      {
        params: {
          code: code,
          name: name,
          status: status,
          is_top: is_top,
          product_type_ids: product_type_ids,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
}
