import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "buttersea",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Sub {
  getSubInfo(id) {
    return axios.get(
      `/api/admin/add-sub-list/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getSubSelectList(name, scope_of_range) {
    return axios.get(
      `/api/admin/add-sub-list/select-list`,
      {
        params: {
          name: name,
          scope_of_range: scope_of_range,
        },
      },
      config
    );
  }
  getSubList(id, name, scope_of_range, page, page_num) {
    return axios.get(
      `/api/admin/add-sub-list/list`,
      {
        params: {
          id: id,
          name: name,
          scope_of_range: scope_of_range,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getCodeList() {
    return axios.get(
      `/api/admin/add-sub-list/code/list`,
      {
        params: {
          page: 1,
          page_num: 999,
        },
      },
      config
    );
  }
  addSub(
    name,
    code,
    dollar,
    percent,
    scope_of_range,
    description
    // product_coupon,
    // line_user_coupon
  ) {
    return axios.post(
      `/api/admin/add-sub-list/`,
      {
        name: name,
        code: code,
        dollar: dollar,
        percent: percent,
        scope_of_range: scope_of_range,
        description: description,
        // product_coupon: product_coupon,
        // line_user_coupon: line_user_coupon,
      },
      config
    );
  }
  editSub(
    id,
    name,
    code,
    dollar,
    percent,
    scope_of_range,
    description
    // product_coupon,
    // line_user_coupon
  ) {
    return axios.put(
      `/api/admin/add-sub-list/`,
      {
        id: id,
        name: name,
        code: code,
        dollar: dollar,
        percent: percent,
        scope_of_range: scope_of_range,
        description: description,
        // product_coupon: product_coupon,
        // line_user_coupon: line_user_coupon,
      },
      config
    );
  }
  editSubStatus(id, status, description) {
    return axios.put(
      `/api/admin/add-sub-list/`,
      {
        id: id,
        status: status,
        description: description,
      },
      config
    );
  }
  deleteSub(id) {
    return axios.delete(
      `/api/admin/add-sub-list`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
