import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";

// import User from "../views/user/User.vue";
import Product from "../views/Product.vue";
import ProductOrder from "../views/ProductOrder.vue";
// import ProductShow from "../views/ProductShow.vue";
import ProductShowCustomer from "../views/ProductShowCustomer.vue";
import Menu from "../views/Menu.vue";
import Share from "../views/Share.vue";

import StoreInfo from "../views/StoreInfo.vue";
import ProductType from "../views/ProductType.vue";
import LineUser from "../views/LineUser.vue";
import OrderLog from "../views/OrderLog.vue";
import ImageRegister from "../views/ImageRegister.vue";
import Sub from "../views/Sub.vue";
import SendMsg from "../views/SendMsg.vue";
import Test from "../views/Test.vue";

const routes = [
  {
    path: "/test",
    name: "Test",
    component: Test,
  },
  {
    path: "/share",
    name: "Share",
    component: Share,
  },
  {
    path: "/bot-order",
    name: "OrderLog",
    component: OrderLog,
  },
  {
    path: "/user",
    name: "LineUser",
    component: LineUser,
  },
  {
    path: "/msg",
    name: "SendMsg",
    component: SendMsg,
  },
  {
    path: "/product-type",
    name: "ProductType",
    component: ProductType,
  },
  {
    path: "/",
    name: "Home",
    component: StoreInfo,
  },
  {
    path: "/store-info",
    name: "StoreInfo",
    component: StoreInfo,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },
  {
    path: "/image-register",
    name: "ImageRegister",
    component: ImageRegister,
  },
  {
    path: "/sub",
    name: "Sub",
    component: Sub,
  },
  {
    path: "/product-show",
    name: "ProductShowCustomer",
    component: ProductShowCustomer,
  },
  // {
  //   path: "/product-show",
  //   name: "Menu",
  //   component: Menu,
  // },
  {
    path: "/product-order",
    name: "ProductOrder",
    component: ProductOrder,
    children: [
      {
        path: "content", // url= shop/cart
        component: ProductOrder,
        // meta: { requiresAuth: false },
      },
      {
        path: "add", // url= shop/cart
        component: ProductOrder,
        // meta: { requiresAuth: false },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
