<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";

import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Chip from "primevue/chip";
import MultiSelect from "primevue/multiselect";
import Dialog from "primevue/dialog";
import FileUpload from "primevue/fileUpload";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Dropdown from "primevue/dropdown";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Product from "../api/salary/admin/product";
import ProductType from "../api/salary/admin/product_type";
import Sub from "../api/salary/admin/sub";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import User from "../api/salary/auth/user";
import firebase from "firebase";
import router from "../router";
import { getBase64 } from "../tool/image";

let userApi = new User();
let subAPI = new Sub();
let productAPI = new Product();
let product_typeAPI = new ProductType();

const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();

const data = reactive({
  selectProductCouponList: [],
  ProductCouponEditSearch: "",
  editCouponDialog: false,
  selectProductTypesList: [],
  selectedEditProductTypes: [],
  selectedAddProductTypes: [],
  selectStatusList: [
    {
      id: 1,
      name: i18n.$t("Product").Disable,
    },
    {
      id: 2,
      name: i18n.$t("Product").Enabled,
    },
    {
      id: 3,
      name: i18n.$t("Product").Freeze,
    },
  ],
  add_product: {
    id: "",
    name: "",
    code: "",
    dollar: 0,
    count: 0,
    image: "",
    is_top: 0,
    new_product_type_ids: [],
    description: "",
  },
  productLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  products: { data: null },
  product: null,
  page: 1,
  page_num: 10,
  selectedProducts: null,
  productFilters: {
    code: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
    is_top: { value: "", matchMode: "contains" },
  },
  selectIsTopList: [
    {
      id: 1,
      name: i18n.$t("Product").NoIsTopShow,
    },
    {
      id: 2,
      name: i18n.$t("Product").IsTopShow,
    },
  ],
});

const beforemounted = onBeforeMount(async () => {
  CheckUser();
});

const mounted = onMounted(async () => {
  data.productLoading = true;
  if (store.state.user != null) {
    getProductList();
    getProductTypeSelectList();
    getSubSelectList();
  }
});

function getSubSelectList() {
  subAPI.getSubSelectList("", 2).then((res) => {
    if (res.data.error_code == 0) {
      // console.log(res.data.data.datas);
      data.selectProductCouponList = res.data.data.datas;
      //   console.log(data.selectPaymentItemList);
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getProductTypeSelectList() {
  product_typeAPI.getProductTypeSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectProductTypesList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getProductList() {
  CheckUser();
  productAPI
    .getProductList(
      data.productFilters.code.value,
      data.productFilters.name.value,
      data.productFilters.status.value,
      data.productFilters.is_top.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.products.data = res.data.data.datas;

        for (let i = 0; i < data.products.data.length; i++) {
          if (data.product != null) {
            if (data.product.id == data.products.data[i].id)
              data.product = data.products.data[i];
          }
        }
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.productLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.productLoading = false;
      }
    });
}

function onUpload(event) {
  getBase64(event.files[0]).then((image_data) => {
    data.add_product.image = image_data
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");
  });
}

function onEditUpload(event) {
  getBase64(event.files[0]).then((image_data) => {
    data.product.image = image_data
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");
  });
}

function saveEditProduct() {
  var regex = new RegExp(/^[a-z]*$/);
  if (!regex.test(data.product.code)) {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail: i18n.$t("Product").ErroMessage,
      life: 3000,
    });
  } else {
    productAPI
      .editProduct(
        data.product.id,
        data.product.name,
        data.product.code,
        String(data.product.dollar),
        String(data.product.count),
        data.product.image,
        data.product.is_top,
        data.product.new_product_type_ids,
        data.product.delete_product_type_ids,
        data.product.description
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("Product").EditSuccess,
            life: 3000,
          });

          data.productLoading = true;
          getProductList();
          data.editDialog = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  }
}

function saveAddProduct() {
  var regex = new RegExp(/^[a-z]*$/);
  if (!regex.test(data.add_product.code)) {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail: i18n.$t("Product").ErroMessage,
      life: 3000,
    });
  } else {
    productAPI
      .addProduct(
        data.add_product.name,
        data.add_product.code,
        String(data.add_product.dollar),
        String(data.add_product.count),
        data.add_product.image,
        data.add_product.is_top,
        data.add_product.new_product_type_ids,
        data.add_product.description
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("Product").AddSuccess,
            life: 3000,
          });

          data.productLoading = true;
          getProductList();
          data.addDialog = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  }
}

function addProduct() {
  CheckUser();
  data.add_product = {
    id: "",
    name: "",
    code: "",
    dollar: 0,
    count: 0,
    image: "",
    is_top: 0,
    new_product_type_ids: [],
    description: "",
  };
  data.IsTopAddSearch = "";
  data.selectedAddProductTypes = [];
  data.addDialog = true;
}
function editProduct(product) {
  CheckUser();
  for (let i = 0; i < data.selectIsTopList.length; i++) {
    if (data.selectIsTopList[i].id == product.is_top) {
      data.IsTopEditSearch = data.selectIsTopList[i];
      break;
    }
  }
  // data.product = { ...product };
  data.product = {
    id: product.id,
    name: product.name,
    code: product.code,
    dollar: product.dollar,
    count: product.count,
    image: product.image,
    image_url: product.image_url,
    is_top: product.is_top,
    new_product_type_ids: [],
    delete_product_type_ids: [],
    product_types: product.product_types,
    description: product.description,
  };

  data.selectedEditProductTypes = product.product_types;
  data.editDialog = true;
}
function editProductCoupon(product) {
  CheckUser();
  data.product = {
    id: product.id,
    new_add_sub_lists: [],
    delete_add_sub_list_ids: [],
    add_sub_lists: product.add_sub_lists,
  };
  data.sub = {
    id: 0,
    min_count: 0,
    max_count: 0,
  };
  data.ProductCouponEditSearch = "";
  data.editCouponDialog = true;
}

function SaveEditProductCoupon() {}
function confirmDeleteSelected() {
  CheckUser();
  data.deleteSelectedDialog = true;
}
function confirmDelete(product) {
  CheckUser();
  data.product = product;
  data.deleteDialog = true;
}
function deleteProduct() {
  productAPI.deleteProduct([data.product.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Product").DeleteSuccess,
        life: 3000,
      });

      data.productLoading = true;
      getProductList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedProduct() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedProducts.length; i++) {
    selectDelete.push(data.selectedProducts[i].id);
  }
  productAPI.deleteProduct(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Product").DeleteSuccess,
        life: 3000,
      });

      data.productLoading = true;
      data.selectedProducts = [];
      getProductList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function editProductStatus(product, status) {
  productAPI.editProductStatus(product.id, status).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Product").EditSuccess,
        life: 3000,
      });

      data.productLoading = true;
      getProductList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.productLoading = true;
    getProductList();
  }
);
watch(
  () => data.productFilters.name.value,
  () => {
    data.productLoading = true;
    getProductList();
  }
);
watch(
  () => data.productFilters.code.value,
  () => {
    data.productLoading = true;
    getProductList();
  }
);
watch(
  () => data.IsTopSearch,
  () => {
    data.productFilters.is_top.value =
      data.IsTopSearch == null ? "" : data.IsTopSearch.id;
    getProductList();
  }
);
watch(
  () => data.StatusSearch,
  () => {
    data.productLoading = true;
    if (data.StatusSearch != null) {
      data.productFilters.status.value =
        data.StatusSearch == null ? "" : data.StatusSearch.id;
    } else {
      data.productFilters.status.value = "";
    }
    getProductList();
  }
);
watch(
  () => data.IsTopEditSearch,
  () => {
    data.product.is_top =
      data.IsTopEditSearch == null ? "" : data.IsTopEditSearch.id;
  }
);
watch(
  () => data.IsTopAddSearch,
  () => {
    data.add_product.is_top =
      data.IsTopAddSearch == null ? "" : data.IsTopAddSearch.id;
  }
);
watch(
  () => data.selectedEditProductTypes,
  async () => {
    let tmpIDs = [];
    let checkIDs = [];
    if (data.selectedEditProductTypes != null)
      for (let i = 0; i < data.selectedEditProductTypes.length; i++) {
        await tmpIDs.push(data.selectedEditProductTypes[i].id);
      }
    if (data.product.product_types != null)
      for (let i = 0; i < data.product.product_types.length; i++) {
        await checkIDs.push(data.product.product_types[i].id);
      }

    data.product.new_product_type_ids = [];
    data.product.delete_product_type_ids = [];

    for (let i = 0; i < tmpIDs.length; i++) {
      if (checkIDs.indexOf(tmpIDs[i]) == -1) {
        await data.product.new_product_type_ids.push(tmpIDs[i]);
      }
    }

    for (let i = 0; i < checkIDs.length; i++) {
      if (
        tmpIDs.indexOf(checkIDs[i]) == -1 &&
        data.product.new_product_type_ids.indexOf(checkIDs[i]) == -1
      ) {
        await data.product.delete_product_type_ids.push(checkIDs[i]);
      }
    }
  }
);

watch(
  () => data.selectedAddProductTypes,
  async () => {
    let tmpIDs = [];
    for (let i = 0; i < data.selectedAddProductTypes.length; i++) {
      await tmpIDs.push(data.selectedAddProductTypes[i].id);
    }
    data.add_product.new_product_type_ids = tmpIDs;
  }
);
watch(
  () => data.selectedAddProductTypes,
  async () => {
    let tmpIDs = [];
    for (let i = 0; i < data.selectedAddProductTypes.length; i++) {
      await tmpIDs.push(data.selectedAddProductTypes[i].id);
    }
    data.add_product.new_product_type_ids = tmpIDs;
  }
);
watch(
  () => data.ProductCouponEditSearch,
  () => {
    data.sub.id =
      data.ProductCouponEditSearch == null
        ? ""
        : data.ProductCouponEditSearch.id;
  }
);
function SaveSub(check, item) {
  if (check) {
    data.product.delete_add_sub_list_ids = [item.id];
  } else {
    data.product.new_add_sub_lists = [
      {
        id: data.sub.id,
        product_coupon: {
          min_count: String(data.sub.min_count),
          max_count: String(data.sub.max_count),
        },
      },
    ];
  }
  productAPI
    .editProductCoupon(
      data.product.id,
      data.product.new_add_sub_lists,
      data.product.delete_add_sub_list_ids
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Product").EditSuccess,
          life: 3000,
        });
        data.ProductCouponEditSearch = "";
        data.sub = {
          id: 0,
          min_count: 0,
          max_count: 0,
        };
        data.productLoading = true;
        getProductList();
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.products.data"
      :loading="data.productLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.productFilters"
      v-model:selection="data.selectedProducts"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Product").ProductTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.productFilters[
                        i18n.$t('Product').ProductTable.Code.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Product').ProductTable.Code.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.productFilters[
                        i18n.$t('Product').ProductTable.Name.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Product').ProductTable.Name.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    class="p-ml-1"
                    v-model="data.StatusSearch"
                    :options="data.selectStatusList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Product').ProductTable.Status.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    class="p-ml-1"
                    v-model="data.IsTopSearch"
                    :options="data.selectIsTopList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Product').ProductTable.IsTop.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('Product').ProductTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addProduct"
            />
            <Button
              :label="i18n.$t('Product').ProductTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedProducts || !data.selectedProducts.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <!-- <Column
        class="p-col-1"
        :field="i18n.$t('Product').ProductTable.ID.Field"
        :header="i18n.$t('Product').ProductTable.ID.Show"
      ></Column> -->
      <Column
        class="p-col-1"
        :field="i18n.$t('Product').ProductTable.Code.Field"
        :header="i18n.$t('Product').ProductTable.Code.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Product').ProductTable.Name.Field"
        :header="i18n.$t('Product').ProductTable.Name.Show"
      ></Column>
      <Column
        class="p-col-2"
        :header="i18n.$t('Product').ProductTable.ProductTypes.Show"
        style="white-space: nowrap; overflow: auto"
      >
        <template #body="slotProps">
          <Chip
            v-for="item in slotProps.data.product_types"
            :key="item.id"
            class="p-mr-1"
          >
            {{ item.name }}
          </Chip>
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Product').ProductTable.Dollar.Field"
        :header="i18n.$t('Product').ProductTable.Dollar.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Product').ProductTable.Count.Field"
        :header="i18n.$t('Product').ProductTable.Count.Show"
      ></Column>
      <Column
        class="p-col-2"
        :header="i18n.$t('Product').ProductTable.Image.Show"
      >
        <template #body="slotProps">
          <img class="product-image" :src="slotProps.data.image_url" />
        </template>
      </Column>
      <Column
        class="p-col-2"
        :header="i18n.$t('Product').ProductTable.IsTop.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.is_top == 1
              ? i18n.$t("Product").Disable
              : slotProps.data.is_top == 2
              ? i18n.$t("Product").Enabled
              : i18n.$t("Product").UnKnow
          }}
        </template>
      </Column>
      <Column class="p-col-2" :header="i18n.$t('Sub').SubTable.Name.Show">
        <template #body="slotProps">
          <Chip
            v-for="item in slotProps.data.add_sub_lists"
            :key="item.id"
            class="p-mr-1"
          >
            {{ item.name }}
          </Chip>
        </template>
      </Column>
      <Column
        class="p-col-2"
        :header="i18n.$t('Product').ProductTable.Status.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.status == 1
              ? i18n.$t("Product").Disable
              : slotProps.data.status == 2
              ? i18n.$t("Product").Enabled
              : slotProps.data.status == 3
              ? i18n.$t("Product").Freeze
              : i18n.$t("Product").UnKnow
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Product').ProductTable.Description.Field"
        :header="i18n.$t('Product').ProductTable.Description.Show"
      ></Column>
      <Column headerStyle="width: 500px" class="p-col-2">
        <template #body="slotProps">
          <Button
            v-tooltip="i18n.$t('Product').ToolTip.Edit"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-info p-mr-2"
            @click="editProduct(slotProps.data)"
          />
          <Button
            v-tooltip="i18n.$t('Product').ToolTip.Coupon"
            icon="pi pi-money-bill"
            class="p-button-rounded p-button-help p-mr-2"
            @click="editProductCoupon(slotProps.data)"
          />
          <Button
            v-tooltip="i18n.$t('Product').ToolTip.Delete"
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning p-mr-2"
            @click="confirmDelete(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.status == 2"
            v-tooltip="i18n.$t('Product').ToolTip.Disable"
            icon="pi pi-ban"
            class="p-button-rounded p-button-danger"
            @click="editProductStatus(slotProps.data, 3)"
          />
          <Button
            v-else
            v-tooltip="i18n.$t('Product').ToolTip.Enabled"
            icon="pi pi-check"
            class="p-button-rounded p-button-success"
            @click="editProductStatus(slotProps.data, 2)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Product').AddProduct.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Product").ProductTable.Code.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_product.code"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Product").ProductTable.Name.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_product.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Product").ProductTable.ProductTypes.Show
        }}</label>
        <MultiSelect
          v-model="data.selectedAddProductTypes"
          :options="data.selectProductTypesList"
          optionLabel="name"
          :placeholder="i18n.$t('Product').ProductTable.ProductTypes.Show"
          :filter="true"
          class="multiselect-custom"
        >
          <template #value="slotProps">
            <div
              class="country-item country-item-value"
              v-for="option of slotProps.value"
              :key="option.id"
            >
              <div>{{ option.name }}</div>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{ i18n.$t("Product").ProductTable.ProductTypes.Show }}
            </template>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </MultiSelect>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Product").ProductTable.Dollar.Show
        }}</label>
        <InputNumber
          v-model="data.add_product.dollar"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Product").ProductTable.Count.Show
        }}</label>
        <InputNumber
          v-model="data.add_product.count"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Product").ProductTable.IsTop.Show
        }}</label>
        <Dropdown
          v-model="data.IsTopAddSearch"
          :options="data.selectIsTopList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Product').ProductTable.IsTop.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Product").ProductTable.Description.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.add_product.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Product").ProductTable.Image.Show
        }}</label>
        <FileUpload
          mode="advanced"
          :showUploadButton="false"
          :chooseLabel="i18n.$t('Product').FileLoad.chooseLabel"
          :cancelLabel="i18n.$t('Product').FileLoad.cancelLabel"
          @uploader="onUpload"
          :multiple="false"
          :customUpload="true"
          :auto="true"
          accept="image/*"
          :maxFileSize="1000000"
        >
          <template #empty>
            <p>{{ i18n.$t("Product").FileLoad.content }}</p>
          </template>
        </FileUpload>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Product').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('Product').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddProduct"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Product').EditProduct.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Product").ProductTable.Code.Show
        }}</label>
        <InputText id="name" v-model.trim="data.product.code" required="true" />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Product").ProductTable.Name.Show
        }}</label>
        <InputText id="name" v-model.trim="data.product.name" required="true" />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Product").ProductTable.ProductTypes.Show
        }}</label>
        <MultiSelect
          v-model="data.selectedEditProductTypes"
          :options="data.selectProductTypesList"
          optionLabel="name"
          :placeholder="i18n.$t('Product').ProductTable.ProductTypes.Show"
          :filter="true"
          class="multiselect-custom"
        >
          <template #value="slotProps">
            <div
              class="country-item country-item-value"
              v-for="option of slotProps.value"
              :key="option.id"
            >
              <div>{{ option.name }}</div>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{ i18n.$t("Product").ProductTable.ProductTypes.Show }}
            </template>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </MultiSelect>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Product").ProductTable.Dollar.Show
        }}</label>
        <InputNumber
          v-model="data.product.dollar"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Product").ProductTable.Count.Show
        }}</label>
        <InputNumber
          v-model="data.product.count"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Product").ProductTable.IsTop.Show
        }}</label>
        <Dropdown
          v-model="data.IsTopEditSearch"
          :options="data.selectIsTopList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Product').ProductTable.IsTop.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Product").ProductTable.Description.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.product.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Product").ProductTable.Image.Show
        }}</label>
        <div v-if="data.product.image_url != ''">
          <img :src="data.product.image_url" style="width: 100%" />
          <Button
            :label="i18n.$t('Product').ProductTable.Delete"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="data.product.image_url = ''"
          />
        </div>
        <FileUpload
          v-else
          mode="advanced"
          :chooseLabel="i18n.$t('Product').FileLoad.chooseLabel"
          :cancelLabel="i18n.$t('Product').FileLoad.cancelLabel"
          :showUploadButton="false"
          @uploader="onEditUpload"
          :multiple="false"
          :customUpload="true"
          :auto="true"
          accept="image/*"
          :maxFileSize="1000000"
        >
          <template #empty>
            <p>{{ i18n.$t("Product").FileLoad.content }}</p>
          </template>
        </FileUpload>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Product').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('Product').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditProduct"
        />
      </template>
    </Dialog>
    <!-- 優惠視窗 -->
    <Dialog
      v-model:visible="data.editCouponDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Sub').AddSub.Title"
      :modal="true"
      class="p-fluid"
    >
      <div>
        <div class="p-grid p-mt-1" style="border-bottom: 1px solid">
          <div class="p-col-4">
            {{ i18n.$t("Sub").SubTable.Name.Show }}
          </div>
          <div class="p-col-3">
            {{ i18n.$t("Sub").SubTable.ProductCoupon.MinCount.Show }}
          </div>
          <div class="p-col-3">
            {{ i18n.$t("Sub").SubTable.ProductCoupon.MaxCount.Show }}
          </div>
          <div class="p-col-2"></div>
        </div>
        <div
          style="
            height: calc(100vh - 470px);
            overflow-y: scroll;
            overflow-x: hidden;
          "
        >
          <div
            :key="index"
            v-for="(item, index) in data.product.add_sub_lists"
            class="p-grid p-mt-1"
            style="border-bottom: 1px solid"
          >
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
                justify-content: center;
              "
              class="p-col-4"
            >
              {{ item.name }}
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
                justify-content: center;
              "
              class="p-col-3"
            >
              {{ item.product_coupon.min_count }}
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
                justify-content: center;
              "
              class="p-col-3"
            >
              {{ item.product_coupon.max_count }}
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
                justify-content: center;
              "
              class="p-col-2"
            >
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger p-button-sm"
                @click="SaveSub(true, item)"
              />
            </div>
          </div>
        </div>
        <div class="p-grid p-mt-1" style="border-top: 1px solid">
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-4"
          >
            <Dropdown
              style="width: 150px"
              v-model="data.ProductCouponEditSearch"
              :options="data.selectProductCouponList"
              optionLabel="name"
              :filter="true"
              :placeholder="i18n.$t('Sub').SubTable.Coupon"
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                  <!-- <div>{{ slotProps.value.dollar }}</div>
              <div>{{ slotProps.value.percent }}</div> -->
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-3"
          >
            <InputNumber
              v-model="data.sub.min_count"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            />
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-3"
          >
            <InputNumber
              v-model="data.sub.max_count"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            />
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
            "
            class="p-col-2"
          >
            <Button
              icon="pi pi-plus-circle"
              class="p-button-rounded p-button-info p-button-sm"
              @click="SaveSub(false, item)"
            />
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Product').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editCouponDialog = false"
        />
        <!-- <Button
          :label="i18n.$t('Product').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveEditProductCoupon"
        /> -->
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Product').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.product"
          >{{ i18n.$t("Product").Confirm.Content }}
          <b>{{ data.product.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Product').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('Product').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteProduct"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Product').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("Product").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Product').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('Product').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedProduct"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
