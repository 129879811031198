import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "buttersea",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class ProductOrder {
  getProductOrderInfo(id) {
    return axios.get(
      `/api/admin/product-order/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getProductOrderList(
    id,
    orderer_name,
    receiver_name,
    delivery_date_begin,
    delivery_date_end,
    predict_delivery_date_begin,
    predict_delivery_date_end,
    status,
    payment_status,
    source_of_order,
    payment_method,
    remark,
    page,
    page_num
  ) {
    return axios.get(
      `/api/admin/product-order/list`,
      {
        params: {
          id: id,
          orderer_name: orderer_name,
          receiver_name: receiver_name,
          delivery_date_begin: delivery_date_begin,
          delivery_date_end: delivery_date_end,
          predict_delivery_date_begin: predict_delivery_date_begin,
          predict_delivery_date_end: predict_delivery_date_end,
          status: status,
          payment_status: payment_status,
          source_of_order: source_of_order,
          payment_method: payment_method,
          remark: remark,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchProductOrder(datas) {
    return axios.post(
      `/api/admin/batch/product-order`,
      {
        datas: datas,
      },
      config
    );
  }
  addProductOrder(
    orderer_name,
    orderer_phone,
    receiver_name,
    receiver_phone,
    delivery_date,
    predict_delivery_date,
    address,
    remark,
    source_of_order,
    payment_status,
    payment_method
  ) {
    return axios.post(
      `/api/admin/product-order`,
      {
        orderer_name: orderer_name,
        orderer_phone: orderer_phone,
        receiver_name: receiver_name,
        receiver_phone: receiver_phone,
        delivery_date: delivery_date,
        predict_delivery_date: predict_delivery_date,
        address: address,
        remark: remark,
        source_of_order: source_of_order,
        payment_status: payment_status,
        payment_method: payment_method,
      },
      config
    );
  }
  editProductOrder(
    id,
    orderer_name,
    orderer_phone,
    receiver_name,
    receiver_phone,
    delivery_date,
    predict_delivery_date,
    address,
    remark,
    source_of_order,
    payment_status,
    payment_method
  ) {
    return axios.put(
      `/api/admin/product-order`,
      {
        id: id,
        orderer_name: orderer_name,
        orderer_phone: orderer_phone,
        receiver_name: receiver_name,
        receiver_phone: receiver_phone,
        delivery_date: delivery_date,
        predict_delivery_date: predict_delivery_date,
        address: address,
        remark: remark,
        source_of_order: source_of_order,
        payment_status: payment_status,
        payment_method: payment_method,
      },
      config
    );
  }
  editProductOrderStatus(id, status) {
    return axios.put(
      `/api/admin/product-order`,
      {
        id: id,
        status: status,
      },
      config
    );
  }
  editProductOrderPaymentStatus(id, payment_status) {
    return axios.put(
      `/api/admin/product-order`,
      {
        id: id,
        payment_status: payment_status,
      },
      config
    );
  }
  deleteProductOrder(id) {
    return axios.delete(
      `/api/admin/product-order`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
