import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "buttersea",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class ProductOrder {
  addProductOrder(
    orderer_name,
    orderer_phone,
    receiver_name,
    receiver_phone,
    delivery_date,
    predict_delivery_date,
    address,
    remark,
    source_of_order,
    payment_status,
    payment_method,
    product_order_content
  ) {
    return axios.post(
      `/line/user/product-order/`,
      {
        orderer_name: orderer_name,
        orderer_phone: orderer_phone,
        receiver_name: receiver_name,
        receiver_phone: receiver_phone,
        delivery_date: delivery_date,
        predict_delivery_date: predict_delivery_date,
        address: address,
        remark: remark,
        source_of_order: source_of_order,
        payment_status: payment_status,
        payment_method: payment_method,
        product_order_content: product_order_content,
      },
      config
    );
  }
  getProductOrderList(
    line_user_user_id,
    created_at_begin,
    created_at_end,
    delivery_date_begin,
    delivery_date_end,
    receiver_name,
    status,
    page,
    page_num
  ) {
    return axios.get(
      `/line/user/product-order/list`,
      {
        params: {
          line_user_user_id: line_user_user_id,
          created_at_begin: created_at_begin,
          created_at_end: created_at_end,
          delivery_date_begin: delivery_date_begin,
          delivery_date_end: delivery_date_end,
          receiver_name: receiver_name,
          status: status,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
}
