<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import FileUpload from "primevue/fileUpload";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import ImageRegister from "../api/salary/admin/image_register";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import User from "../api/salary/auth/user";
import { getBase64 } from "../tool/image";
import firebase from "firebase";
import router from "../router";

let userApi = new User();
let image_registerAPI = new ImageRegister();
const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  add_image_register: {
    id: "",
    name: "",
    image: "",
  },
  image_registerLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  image_registers: { data: null },
  image_register: null,
  page: 1,
  page_num: 10,
  selectedImageRegisters: null,
  image_registerFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  CheckUser();
});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getImageRegisterList();
  }
});
function onUpload(event) {
  getBase64(event.files[0]).then((image_data) => {
    data.add_image_register.image = image_data
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");
  });
}

function onEditUpload(event) {
  getBase64(event.files[0]).then((image_data) => {
    data.image_register.image = image_data
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");
  });
}
function getImageRegisterList() {
  CheckUser();
  image_registerAPI
    .getImageRegisterList(
      data.image_registerFilters.id.value,
      data.image_registerFilters.name.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.image_registers.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.image_registerLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.image_registerLoading = false;
      }
    });
}

function saveEditImageRegister() {
  image_registerAPI
    .editImageRegister(
      data.image_register.id,
      data.image_register.name,
      data.image_register.image
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("ImageRegister").EditSuccess,
          life: 3000,
        });

        data.image_registerLoading = true;
        getImageRegisterList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function saveAddImageRegister() {
  image_registerAPI
    .addImageRegister(
      data.add_image_register.name,
      data.add_image_register.image
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("ImageRegister").AddSuccess,
          life: 3000,
        });

        data.image_registerLoading = true;
        getImageRegisterList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function addImageRegister() {
  CheckUser();
  data.addDialog = true;
}
function editImageRegister(image_register) {
  CheckUser();
  data.image_register = { ...image_register };
  data.editDialog = true;
}
function confirmDeleteSelected() {
  CheckUser();
  data.deleteSelectedDialog = true;
}
function confirmDelete(image_register) {
  CheckUser();
  data.image_register = image_register;
  data.deleteDialog = true;
}
function deleteImageRegister() {
  image_registerAPI
    .deleteImageRegister([data.image_register.id])
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("ImageRegister").DeleteSuccess,
          life: 3000,
        });

        data.image_registerLoading = true;
        getImageRegisterList();
        data.deleteDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function deleteSelectedImageRegister() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedImageRegisters.length; i++) {
    selectDelete.push(data.selectedImageRegisters[i].id);
  }
  image_registerAPI.deleteImageRegister(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("ImageRegister").DeleteSuccess,
        life: 3000,
      });

      data.image_registerLoading = true;
      data.selectedImageRegisters = [];
      getImageRegisterList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.image_registerLoading = true;
    getImageRegisterList();
  }
);
watch(
  () => data.image_registerFilters.name.value,
  () => {
    data.image_registerLoading = true;
    getImageRegisterList();
  }
);
watch(
  () => data.image_registerFilters.id.value,
  () => {
    data.image_registerLoading = true;
    getImageRegisterList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.image_registers.data"
      :loading="data.image_registerLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.image_registerFilters"
      v-model:selection="data.selectedImageRegisters"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{
                  i18n.$t("ImageRegister").ImageRegisterTable.Search
                }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.image_registerFilters[
                        i18n.$t('ImageRegister').ImageRegisterTable.ID.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('ImageRegister').ImageRegisterTable.ID.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.image_registerFilters[
                        i18n.$t('ImageRegister').ImageRegisterTable.Name.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('ImageRegister').ImageRegisterTable.Name.Show
                    "
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('ImageRegister').ImageRegisterTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addImageRegister"
            />
            <Button
              :label="i18n.$t('ImageRegister').ImageRegisterTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedImageRegisters ||
                !data.selectedImageRegisters.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('ImageRegister').ImageRegisterTable.ID.Field"
        :header="i18n.$t('ImageRegister').ImageRegisterTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('ImageRegister').ImageRegisterTable.Name.Field"
        :header="i18n.$t('ImageRegister').ImageRegisterTable.Name.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('ImageRegister').ImageRegisterTable.Image.Field"
        :header="i18n.$t('ImageRegister').ImageRegisterTable.Image.Show"
      >
        <template #body="slotProps">
          <img class="product-image" :src="slotProps.data.image_url" />
        </template>
      </Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editImageRegister(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('ImageRegister').AddImageRegister.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("ImageRegister").AddImageRegister.Name
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_image_register.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("ImageRegister").ImageRegisterTable.Image.Show
        }}</label>
        <FileUpload
          mode="advanced"
          :showUploadButton="false"
          :chooseLabel="i18n.$t('ImageRegister').FileLoad.chooseLabel"
          :cancelLabel="i18n.$t('ImageRegister').FileLoad.cancelLabel"
          @uploader="onUpload"
          :multiple="false"
          :customUpload="true"
          :auto="true"
          accept="image/*"
          :maxFileSize="1000000"
        >
          <template #empty>
            <p>{{ i18n.$t("ImageRegister").FileLoad.content }}</p>
          </template>
        </FileUpload>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('ImageRegister').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('ImageRegister').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddImageRegister"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('ImageRegister').EditImageRegister.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("ImageRegister").EditImageRegister.Name
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.image_register.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("ImageRegister").ImageRegisterTable.Image.Show
        }}</label>
        <!-- <div v-if="data.image_register.image_url != ''"> -->
        <div>
          <img :src="data.image_register.image_url" style="width: 100%" />
          <!-- <Button
            :label="i18n.$t('ImageRegister').ImageRegisterTable.Delete"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="data.image_register.image_url = ''"
          /> -->
        </div>
        <FileUpload
          mode="advanced"
          :chooseLabel="i18n.$t('ImageRegister').FileLoad.chooseLabel"
          :cancelLabel="i18n.$t('ImageRegister').FileLoad.cancelLabel"
          :showUploadButton="false"
          @uploader="onEditUpload"
          :multiple="false"
          :customUpload="true"
          :auto="true"
          accept="image/*"
          :maxFileSize="1000000"
        >
          <template #empty>
            <p>{{ i18n.$t("ImageRegister").FileLoad.content }}</p>
          </template>
        </FileUpload>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('ImageRegister').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('ImageRegister').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditImageRegister"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('ImageRegister').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.image_register"
          >{{ i18n.$t("ImageRegister").Confirm.Content }}
          <b>{{ data.image_register.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('ImageRegister').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('ImageRegister').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteImageRegister"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('ImageRegister').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("ImageRegister").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('ImageRegister').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('ImageRegister').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedImageRegister"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
