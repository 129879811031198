import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "buttersea",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Auth {
  checkUser() {
    return axios.get(
      `/line/user`,
      {
        params: {},
      },
      config
    );
  }
  loginUser(state, code) {
    return axios.post(
      `/line/login`,
      {
        state: state,
        code: code,
      },
      config
    );
  }
  loginFrontendUser(user_id, name, key) {
    return axios.post(
      `/line/login-frontend`,
      {
        user_id: user_id,
        name: name,
        key: key,
      },
      config
    );
  }
}
